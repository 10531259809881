<!--
    Created by 程雨喵'mac on 2023/12/28.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：
    功能概述：
-->
<style lang="less">
.permission-amount-analysis {
  display: flex;
  align-items: flex-end;
  font-size: 13px;
  line-height: 13px;
  &+.info-item {
    margin-top: 10px;
  }
  .label {
    color: @textColorLight;
  }
  .value {
    padding: 0 @containerGap * 0.5;
    color: @textColorStrong;
    &.big {
      font-weight: 600;
      font-size: 15px;
      line-height: 15px;
      color: @adornColor;
    }
    .strong {
      color: @adornColor;
      font-weight: 600;
    }
  }
  .sure-btn {
    padding: 0 @containerGap * 1.2;
    line-height: 24px;
    background-color: @themeColor;
    color: white;
    border-radius: 6px;
    cursor: pointer;
  }
}
</style>

<template>
  <yg-drawer
    width="1300"
    :title="model.id ? '编辑' : '新增'"
    :back-type="2"
    @on-sure="onSure"
    @on-close="onClose"
  >
    <div
      slot="content"
      style="padding-bottom: 15px"
    >
      <yg-card
        title="角色信息"
      >
        <div slot="content">
          <Form style="max-width: 400px">
            <FormItem
              v-for="(item, i) in inputItems"
              :key="i"
              :label="item.label + ':'"
              :label-width="item.labelWidth"
              :required="item.isRequired"
            >
              <Input
                v-model="model[item.key]"
                :placeholder="item.placeholder"
                @input="checkSubmit"
              />
            </FormItem>
          </Form>
        </div>
      </yg-card>
      <yg-card
        title="角色权限"
        style="margin-top: 15px"
      >
        <div slot="content">
          <v-role-list
            :model="model"
            @on-change="onChangeRole"
          />
        </div>
      </yg-card>
    </div>
    <div slot="button">
      <div
        class="permission-amount-analysis"
        style="align-items: center"
      >
        <!--<span class="label">已选权限:</span>-->
        <!--<span class="value"><span class="strong">{{ amountArr[0][0] }}</span>/{{ amountArr[0][1] }}个一级菜单</span>-->
        <!--<span class="value"><span class="strong">{{ amountArr[1][0] }}</span>/{{ amountArr[1][1] }}个二级菜单</span>-->
        <!--<span class="value"><span class="strong">{{ amountArr[2][0] }}</span>/{{ amountArr[2][1] }}个菜单按钮</span>-->
      </div>
    </div>
  </yg-drawer>
</template>

<script>
import UserApiSet from '@/request/api/UserApiSet';
import vRoleList from './roleList.vue';
export default {
  components: { vRoleList },
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      inputItems: [
        { label: '角色名称', labelWidth: 90, placeholder: '输入的名称最短2个字，最长可输入20个字，可汉字、数字、字母或符号组合', key: 'roleName', isRequired: true },
        { label: '角色描述', labelWidth: 90, placeholder: '输入的描述最长可输入40个字', key: 'description', isRequired: false }
      ],
      amountArr: [[0, 0], [0, 0], [0, 0]],
      // id数组
      selectList: [],
      // 对象数组
      selectModelList: []
    };
  },
  created () {
    this.$nextTick(() => {
      this.checkSubmit();
    });
  },
  methods: {
    onSure () {
      const resData = this.model.checkValidAccountSubmit();
      if (resData.success) {
        if (this.selectList.length === 0) {
          // 可以置空，直接放行
          this.requestSubmit(resData.params);
        } else {
          // 校验首页是否已给
          const dashboard = this.selectModelList.find(v => v.path === 'dashboard' && v.sel);
          if (dashboard) {
            // 有首页直接放行
            this.requestSubmit(resData.params);
          } else {
            // 没首页，拦截并提示
            this.$Message.warning('请给与首页访问权限');
          }
        }
      } else {
        this.$Message.warning(resData.msg);
      }
    },
    onClose () {
      this.$emit('on-close');
    },
    onChangeRole (e) {
      // this.amountArr = JSON.parse(JSON.stringify(e.amount));
      this.selectList = e.list;
      this.selectModelList = e.orgList;
    },
    checkSubmit () {
      const item = this.inputItems.find(v => !this.model[v.key]);
      this.$emit('on-change', {
        disabled: !!item,
        msg: item ? (item.label + '不可为空') : ''
      });
    },
    requestSubmit (params) {
      const api = this.model.id ? UserApiSet.roleInfoEdit : UserApiSet.roleInfoAdd;
      params.menus = this.selectList;
      api.params = params;
      if (this.model.id) {
        api.params.id = this.model.id;
      }
      this.$http(api).then(res => {
        this.$Message.success('操作成功');
        this.$emit('on-sure');
      });
    }
  }
};
</script>
