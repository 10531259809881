<!--
    Created by 程雨喵'mac on 2024/12/24.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    界面名称：版本列表
-->
<style lang="less">
.device-type-version-list {
  padding: @containerGap;
  background-color: @backColorStrong;
  border-radius: @borderRadiusMid;
}
</style>

<template>
  <yg-drawer
    title="软件版本管理"
    width="100%"
    :back-type="2"
    :show-bottom="false"
    @on-close="onClose"
  >
    <div
      slot="content"
      class="device-type-version-list"
    >
      <Alert
        type="warning"
        show-icon
      >
        谨慎操作
        <div slot="desc">
          <p>1、本页面仅限技术研发部门人员操作。</p>
          <p>2、非技术研发部门人员操作时，请在专业人士的指导下操作。</p>
        </div>
      </Alert>
      <yg-setting-area
        :items="settingItems"
        :total="page.total"
        @on-click="_clickSetting"
      />
      <div class="padding-primary-top">
        <yg-table
          :columns="tableColumns"
          :data="tableData"
          :page="page"
          @on-page-change="onChangePage"
        />
      </div>
      <v-submit
        v-if="!!dialog.submitModel"
        :model="dialog.submitModel"
        @on-close="onCloseSubmit"
      />
    </div>
  </yg-drawer>
</template>

<script>
import ColumnMixin from '@/pages/030__basicSetting/deviceTypeList/mixin/versionTableColumn';
import { DeviceTypeVersionModel } from '@/pages/030__basicSetting/deviceTypeList/model/DeviceTypeVersionDataModel';
import vSubmit from './versionSubmit.vue';
import SoftVersionApiSet from '@/request/api/SoftVersionApiSet';

export default {
  components: { vSubmit },
  mixins: [ColumnMixin],
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      settingItems: [
        BaseSettingModel.initData('新增', 'add', 'md-add-circle').setPermission('system:device:addDeviceVersion'),
        BaseSettingModel.initData('刷新', 'refresh', 'md-refresh-circle').setPermission('system:device:getDeviceVersions')
      ],
      page: { current: 1, total: 0 },
      tableData: [],
      dialog: {
        submitModel: null
      },
      // 外部页面是否需要刷新
      needRefresh: false
    };
  },
  created () {
    this.requestData();
  },
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    onClose () {
      this.$emit('on-close', this.needRefresh);
    },
    onCloseSubmit (data) {
      if (data) {
        this.requestSubmit(data);
      } else {
        this.dialog.submitModel = null;
      }
    },
    // 分页
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    // 设置功能
    _clickSetting (key) {
      switch (key) {
      case 'add':
        this.dialog.submitModel = DeviceTypeVersionModel.createAdditionModel(this.model);
        break;
      case 'refresh':
        this.page.current = 1;
        this.requestData();
        break;
      }
    },
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    requestData () {
      const { manufacturers, deviceCategory, portCategory } = this.model;
      this.tableData = [];
      const api = SoftVersionApiSet.getDeviceVersions;
      api.params = {
        manufacturers,
        deviceCategory,
        portCategory,
        pageSize: this.$config.pageSize,
        pageNo: this.page.current
      };
      this.$http(api).then(res => {
        const resData = res.data;
        this.page.total = resData.total;
        this.tableData = DeviceTypeVersionModel.initListWithDataList(resData.rows);
      });
    },
    requestSubmit (params) {
      const api = this.dialog.submitModel.id ? SoftVersionApiSet.updateDeviceVersion : SoftVersionApiSet.addDeviceVersion;
      api.params = params;
      this.$httpFormData(api).then(res => {
        this.dialog.submitModel = null;
        this.$Message.success('操作成功');
        this.needRefresh = true;
        this.onChangePage(1);
      });
    },
    requestDel (id) {
      const api = SoftVersionApiSet.deleteDeviceVersion;
      api.params = {
        id
      };
      this.$http(api).then(res => {
        this.$Message.success('操作成功');
        this.needRefresh = true;
        this.onChangePage(1);
      });
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
