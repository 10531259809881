<!--
    Created by 程雨喵'mac on 2023/12/12.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：设备类型管理 - 设备类型列表
-->
<style lang="less">
#device-type-list-content {}
</style>

<template>
  <div id="device-type-list-content">
    <div>
      <yg-setting-area
        :items="settingItems"
        :total="page.total"
        @on-click="_clickSetting"
      />
    </div>
    <div class="padding-primary-top">
      <yg-table
        :columns="tableColumns"
        :data="tableData"
        :page="page"
        @on-page-change="onChangePage"
      />
    </div>
    <!--弹窗：提交(新增/编辑)-->
    <v-submit
      v-if="!!dialog.submitModel"
      :model="dialog.submitModel"
      @on-close="dialog.submitModel = null"
      @on-sure="_clickSure"
    />
    <!--版本管理-->
    <v-version-list
      v-if="!!dialog.versionModel"
      :model="dialog.versionModel"
      @on-close="dialog.versionModel = null"
    />
  </div>
</template>

<script>
import { DeviceTypeDataModel } from './model/DeviceTypeDataModel';
import BasicsApiSet from '@/request/api/BasicsApiSet';
import ColumnMixin from '@/pages/030__basicSetting/deviceTypeList/mixin/tableColumn';
import vSubmit from './view/submit.vue';
import vVersionList from './view/version/list.vue';
import { mapActions } from 'vuex';

export default {
  components: { vSubmit, vVersionList },
  mixins: [ColumnMixin],
  data () {
    return {
      settingItems: [
        BaseSettingModel.initData('新增', 'add', 'md-add-circle').setPermission('system:deviceType:add'),
        BaseSettingModel.initData('刷新数据', 'refresh', 'md-refresh-circle')
      ],
      page: { current: 1, total: 0 },
      tableData: [],
      dialog: {
        submitModel: null,
        versionModel: null
      },
      deviceTypeList: [],
      chargingPileTypeList: [],
      factoryTypeList: []
    };
  },
  created () {
    Promise.all([this.getDeviceTypeList(), this.getChargingPileTypeList(), this.getFactoryTypeList()]).then(res => {
      this.deviceTypeList = res[0];
      this.chargingPileTypeList = res[1];
      this.factoryTypeList = res[2];
      this.requestData();
    });
  },
  methods: {
    ...mapActions(['getDeviceTypeList', 'getChargingPileTypeList', 'getFactoryTypeList']),
    /** ******************* 工具方法开始 ******************* **/
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    // 分页
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    // 设置功能
    _clickSetting (key) {
      switch (key) {
      case 'add':
        this.dialog.submitModel = DeviceTypeDataModel.createAdditionModel();
        break;
      case 'refresh':
        this.page.current = 1;
        this.requestData();
        break;
      }
    },
    _clickSure (params) {
      this.requestSubmit(params);
    },
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    requestData () {
      this.tableData = [];
      this.page.total = 0;
      const api = BasicsApiSet.getListApi;
      api.params = {
        pageSize: this.$config.pageSize,
        pageNo: this.page.current
      };
      this.$http(api).then(res => {
        const resData = res.data;
        this.page.total = resData.total;
        resData.rows.map(v => {
          v.deviceTypeList = this.deviceTypeList;
          v.chargingPileTypeList = this.chargingPileTypeList;
          v.factoryTypeList = this.factoryTypeList;
        });
        this.tableData = DeviceTypeDataModel.initListWithDataList(resData.rows);
      });
    },
    // 切换状态
    requestChangeStatus (row, status) {
      const api = BasicsApiSet.changeStatusApi;
      api.params = {
        id: row.id,
        status: status ? 1 : 0
      };
      this.$http(api)
        .then(res => {
          console.log(res);
          this.$Message.success('操作成功');
          this.requestData();
        })
        .catch(e => {
          row.status = status ? 0 : 1;
          row.h5StatusSwitch = !status;
        });
    },
    // 请求新增/编辑
    requestSubmit (params) {
      console.log(params);
      let api = {};
      if (params.deviceTypeId) {
        api = BasicsApiSet.editDeviceTypeApi;
      } else {
        api = BasicsApiSet.addDeviceTypeApi;
      }
      api.params = params;
      this.$http(api).then(res => {
        this.$Message.success('操作成功');
        this.dialog.submitModel = null;
        this.requestData();
      });
    },
    // 获取设备详情
    requestInfo (id) {
      const api = BasicsApiSet.getInfoApi;
      api.params = { id };
      this.$http(api).then(res => {
        this.dialog.submitModel = DeviceTypeDataModel.initModelWithData(res.data);
      });
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
