<!--
    Created by 程雨喵'mac on 2024/2/5.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
-->
<style lang="less">
#page-role-menu-management {
  position: relative;
  height: 100%;
  line-height: 14px;
  border-radius: @containerGap;
  @rowH: 20px;
  .page-header-top {
    position: absolute;
    right: 0;
    top: -35px;
    display: flex;
    justify-content: flex-end;
  }
  .page-content {
    @treePaddingLeft: 25px;
    @checkBoxWidth: 17px;
    padding: 0 @containerGap @containerGap;
    background-color: @backColorNormal;
    height: 100%;
    border-radius: @borderRadiusMid;
    overflow: auto;
    .permission-header {
      padding-bottom: 8px;
      font-weight: 600;
      line-height: @rowH;
      font-size: 14px;
      background-color: transparent !important;
      text-align: center;
    }
    .permission-ul {
      border: 1px solid lightgray;
      border-top: 0;
      border-bottom: 0;
    }
    .permission-row {
      padding: 10px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid lightgray;
      font-size: 12px;
      .ivu-icon-md-checkbox {
        margin-right: 3px;
        font-size: 14px;
        color: #a0a0a0;
        &.is-sel {
          color: @themeColor;
        }
      }
      .width-200 {
        margin-right: @containerGap;
        .flex-grow(180px);
      }
      .width-flex {
        flex: 1;
      }
      &:nth-of-type(1n) {
        background-color: @backColorStrong;
      }
      &:nth-of-type(2n) {
        background-color: @backColorNormal;
      }
      .menu-big-item {
        position: relative;
        .display-flex();
        padding-left: 10px;
        justify-content: flex-start;
        border-radius: 6px;
        height: @rowH * 1.5;
        font-weight: 600;
        background-color: white;
        border: 1px solid lightgray;
        cursor: pointer;
        &:after {
          content: '';
          position: absolute;
          right: -8px;
          width: 8px;
          height: 2px;
          top: 50%;
          transform: translateY(-50%);
          background-color: lightgray;
        }
      }
      .menu-mid-item {
        position: relative;
        .display-flex();
        padding-left: 10px;
        justify-content: flex-start;
        border-radius: 6px;
        height: @rowH * 1.2;
        background-color: white;
        border: 1px solid lightgray;
        cursor: pointer;
        &:after {
          content: '';
          position: absolute;
          left: -9px;
          width: 8px;
          height: 2px;
          top: 50%;
          transform: translateY(-50%);
          background-color: lightgray;
        }
      }
      .menu-2-row {
        padding: 5px 0;
        position: relative;
        display: flex;
        align-items: center;
        &:first-child {
          &:before {
            display: none;
          }
        }
        &:last-child {
          &:after {
            display: none !important;
          }
        }
        &:before {
          //向上
          content: '';
          position: absolute;
          left: -9px;
          width: 2px;
          top: 0;
          height: 50%;
          background-color: lightgray;
          //background-color: red;
        }
        &:after {
          //向下
          content: '';
          position: absolute;
          left: -9px;
          width: 2px;
          bottom: 0;
          height: 50%;
          background-color: lightgray;
          //background-color: blue;
        }
      }
      .menu-3-box-outer {
        position: relative;
        padding: 8px;
        background-color: white;
        border-radius: 6px;
        border: 1px solid lightgray;
        &:after {
          content: '';
          position: absolute;
          left: -16px;
          width: 16px;
          height: 2px;
          top: 50%;
          transform: translateY(-50%);
          background-color: lightgray;
        }
      }
      .menu-3-box {
        display: flex;
        flex-wrap: wrap;
        &+.menu-3-box {
          border-bottom: 1px solid lightgray;
        }
        .menu-3-tit {
          padding-bottom: 8px;
          .flex-grow(100%);
          font-weight: 600;
        }
        .menu-3-item {
          margin-right: 10px;
          margin-bottom: 8px;
          cursor: pointer;
          &.has-children {
            .flex-grow(100%);
            margin-right: 0;
            .button-p-box {
              display: flex;
              padding-left: @containerGap;
              padding-top: 10px;
              .menu-4-item {
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <div id="page-role-menu-management">
    <div class="page-header-top">
      <yg-button
        type="text"
        size="small"
        @click="_clickSelAll"
      >
        一键全选
      </yg-button>
      <yg-button
        type="text"
        size="small"
        style="margin-left: 15px"
        @click="_clickUnSelAll"
      >
        一键清空
      </yg-button>
      <yg-button
        type="text"
        size="small"
        style="margin-left: 15px"
        @click="_clickOpenAll"
      >
        全部展开
      </yg-button>
    </div>
    <div class="display-flex display-flex-h-end padding-primary-bottom">
      <div class="display-flex display-flex-v-center">
        是否连带勾选：
        <i-switch
          v-model="isRelatedSel"
          size="large"
        >
          <span slot="open">开启</span>
          <span slot="close">禁用</span>
        </i-switch>
      </div>
    </div>
    <div class="page-content">
      <div class="permission-row permission-header">
        <div class="width-200">
          一级菜单
        </div>
        <div class="width-200">
          二级菜单
        </div>
        <div class="width-flex">
          按钮权限
        </div>
      </div>
      <div class="permission-ul">
        <div
          v-for="(item, i) in list"
          :key="i"
          class="permission-row"
        >
          <div
            class="width-200 menu-big-item"
            @click.stop="_clickSel(item)"
          >
            <Icon
              type="md-checkbox"
              :class="item.sel ? 'is-sel' : ''"
            />
            {{ item.menuTitle }}
          </div>
          <div
            v-if="item.sel || item.open"
            class="width-flex"
          >
            <div
              v-for="(sub, s) in item.children"
              :key="s + '-s'"
              class="menu-2-row"
            >
              <div
                class="width-200 menu-mid-item"
                @click.stop="_clickSel(sub, item)"
              >
                <Icon
                  type="md-checkbox"
                  :class="sub.sel ? 'is-sel' : ''"
                />
                {{ sub.menuTitle }}
              </div>
              <div
                v-if="sub.children && sub.children.length"
                class="width-flex menu-3-box-outer"
              >
                <!--按钮权限-->
                <div
                  class="menu-3-box"
                >
                  <p class="menu-3-tit">
                    按钮权限
                  </p>
                  <div
                    v-for="(chi, c) in sub.children"
                    :key="c + '-c'"
                    class="menu-3-item"
                    @click.stop="_clickSel(chi, sub, item)"
                  >
                    <Icon
                      type="md-checkbox"
                      :class="chi.sel ? 'is-sel' : ''"
                    />
                    {{ chi.menuTitle }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            class="width-flex"
          >
            <div
              class="menu-2-row"
            >
              <div
                class="width-200 menu-mid-item"
                style="justify-content: center; padding-right: 15px"
              >
                无权限
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import permissionMixin from '../mixins/permission';
export default {
  components: {},
  mixins: [permissionMixin],
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      // 是否是连带勾选(默认开启)
      isRelatedSel: true
    };
  },
  created () {
    this.requestData();
  },
  methods: {
    valueChanged () {
      this.$emit('on-change', {
        // amount: this.amountArr,
        list: this.getAllSelIds(this.list),
        orgList: this.list
      });
    },
    _clickSel (item, father, grandfather) {
      item.sel = !item.sel;
      this.setAmountItem(item);
      if (this.isRelatedSel) {
        if (item.children && item.children.length) {
          this.setChildrenSel(item.children, item.sel);
        }
        if (item.sel) {
          item.open = item.sel;
          if (father) {
            father.sel = true;
          }
          if (grandfather) {
            grandfather.sel = true;
          }
        }
      } else {
        item.open = true;
      }
      this._clickSave();
    },
    _clickSelAll () {
      // this.amountArr[0][0] = 0;
      // this.amountArr[1][0] = 0;
      // this.amountArr[2][0] = 0;
      this.setChildrenSel(this.list, true);
      this._clickSave();
    },
    _clickUnSelAll () {
      this.setChildrenSel(this.list, false);
      // this.amountArr[0][0] = 0;
      // this.amountArr[1][0] = 0;
      // this.amountArr[2][0] = 0;
      this._clickSave();
    },
    setOpenChange (list) {
      list.forEach(v => {
        if (v.children && v.children.length) {
          this.setOpenChange(v.children);
        }
        v.open = true;
      });
    },
    _clickOpenAll () {
      this.setOpenChange(this.list);
    },
    // 点击保存
    _clickSave () {
      this.valueChanged();
    }
  }
};
</script>
