/**
 * @date:2024/1/23
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 * 设备信息相关请求
 */

import BaseApi from '@/request/base/BaseApi';

class DeviceApiSet {
  constructor () {
    this.deviceList = BaseApi.createModel('获取设备信息列表', '/opmp/device/list', true);
    this.deviceInfo = BaseApi.createModel('获取设备详情', '/opmp/device/info', true).setMethod(1);
    this.deviceChangeStatus = BaseApi.createModel('服务商修改状态', '/opmp/device/changeStatus').setMethod(2);
    this.devicePrice = BaseApi.createModel('获取设备计费', '/opmp/device/price/get', true).setMethod(1);
    this.devicePriceSet = BaseApi.createModel('设置设备计费', '/opmp/device/price/set', true).setMethod(1);
    this.deviceSeparateAccount = BaseApi.createModel('获取分账占比', '/opmp/device/separateAccount/get', true).setMethod(0, false);
    this.deviceSeparateAccountSet = BaseApi.createModel('设置分账占比', '/opmp/device/separateAccount/set', true).setMethod(1);
    this.deviceChargeGet = BaseApi.createModel('获取设备充电配置', '/opmp/device/charge/get', true).setMethod(0, false);
    this.deviceChargeSet = BaseApi.createModel('设备充电配置', '/opmp/device/charge/set', true).setMethod(1);
    this.portList = BaseApi.createModel('获取设备端口信息列表', '/opmp/device/port/list', true).setMethod(0);
    this.portChangeStatus = BaseApi.createModel('端口修改状态', '/opmp/device/port/changeStatus').setMethod(2);
    this.devicePortRefresh = BaseApi.createModel('刷新端口', '/opmp/device/refresh', true).setMethod(0, false);
    this.deviceDel = BaseApi.createModel('删除设备', '/opmp/device/delete', true).setMethod(2);
    this.deviceReset = BaseApi.createModel('重新启动设备', '/opmp/device/reboot', true).setMethod(0, false);
    this.deviceClear = BaseApi.createModel('设备清除缓存', '/opmp/device/memory/clear', true).setMethod(0, false);
    this.deviceOperationType = BaseApi.createModel('设置设备运营参数', '/opmp/device/operationType/set', true).setMethod(1);
    this.swipeCardCostNumberSet = BaseApi.createModel('设置设备刷卡扣充电币数量', '/opmp/device/deviceParam/set', true).setMethod(1);
    this.offlineList = BaseApi.createModel('', '/opmp/device/offlineList', true).setMethod(1);

    // 设备出厂相关
    this.factoryRecordList = BaseApi.createModel('获取设备出厂信息列表', '/opmp/device/factoryRecord/list', true).setMethod(0);
    this.getDeviceTypeList = BaseApi.createModel('根据制造商获得设备类型', '/opmp/device/factoryRecord/getDeviceType', true);
    this.uploadFactoryRecord = BaseApi.createModel('设备导入', '/opmp/device/uploadFactoryRecord', true);

    // 友电获取高级参数
    this.deviceParam1 = BaseApi.createModel('获取设备运行参数3', '/opmp/device/runtime/param3/qry', true).setMethod(0, false);
    this.deviceParam3 = BaseApi.createModel('获取设备运行参数2', '/opmp/device/runtime/param2/qry', true).setMethod(0, false);
    this.deviceParam2 = BaseApi.createModel('获取设备运行参数1', '/opmp/device/runtime/param1/qry', true).setMethod(0, false);

    // 更新设备名称
    this.modDeviceName = BaseApi.createModel('更新设备名称', '/opmp/device/update/deviceName', true).setMethod(1);
    this.modImei = BaseApi.createModel('更新设备IMEI', '/opmp/device/update/imei', true).setMethod(1);
    this.changeSite = BaseApi.createModel('设备变更点位', '/opmp/device/changeSite', true).setMethod(1);
    this.maxPortPowerOption = BaseApi.createModel('获取设备端口最大功率选项', '/opmp/device/maxPortPower/option', true).setMethod(0);

    // 友电提交高级参数
    this.submitDeviceParam1 = BaseApi.createModel('提交设备运行参数3', '/opmp/device/runtime/param3/set', true);
    this.submitDeviceParam3 = BaseApi.createModel('提交设备运行参数2', '/opmp/device/runtime/param2/set', true);
    this.submitDeviceParam2 = BaseApi.createModel('提交设备运行参数1', '/opmp/device/runtime/param1/set', true);

    // 超翔获取高级参数
    this.deviceCXParam1 = BaseApi.createModel('获取设备运行参数1', '/opmp/device/runtime/cx/param1/qry', true).setMethod(0, false);
    this.deviceCXParam2 = BaseApi.createModel('获取设备运行参数2', '/opmp/device/runtime/cx/param2/qry', true).setMethod(0, false);
    this.deviceCXParam3 = BaseApi.createModel('获取设备运行参数3', '/opmp/device/runtime/cx/param3/qry', true).setMethod(0, false);

    // 超翔提交高级参数
    this.submitDeviceCXParam1 = BaseApi.createModel('提交设备运行参数1', '/opmp/device/runtime/cx/param1/set', true);
    this.submitDeviceCXParam2 = BaseApi.createModel('提交设备运行参数2', '/opmp/device/runtime/cx/param2/set', true);
    this.submitDeviceCXParam3 = BaseApi.createModel('提交设备运行参数3', '/opmp/device/runtime/cx/param3/set', true);
  }
}

export default new DeviceApiSet();
