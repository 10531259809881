<!--
    Created by 程雨喵'mac on 2024/10/22.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：运营设置
-->
<style lang="less">
.charging-pie-setting-operate-rules {
  .top {
    padding: @containerGap 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .__g-button {
      height: auto;
    }
  }
}
</style>

<template>
  <div class="charging-pie-setting-operate-rules">
    <p class="h4">
      运营参数设置
    </p>
    <div class="top">
      <div class="category-b">
        <span>选择设置项：</span>
        <RadioGroup
          v-model="currentCategory"
          @on-change="onChangeRadio"
        >
          <Radio
            v-for="(item, i) in categoryOption"
            :key="i"
            :label="item.value"
            :disabled="isEdit"
          >
            {{ item.label }}
          </Radio>
        </RadioGroup>
      </div>
      <div style="padding-bottom: 4px;">
        <yg-button
          type="text"
          @click="_clickChangeEdit"
        >
          {{ isEdit ? '退出编辑' : '编辑规则' }}
        </yg-button>
      </div>
    </div>
    <template v-if="currentModel">
      <!--按功率计费-->
      <v-port-power
        v-if="currentCategory === 1"
        ref="portPowerRef"
        :is-edit="isEdit"
        :model="currentModel"
      />
      <v-card-money
        v-else-if="currentCategory === 2"
        ref="cardMoneyRef"
        :is-edit="isEdit"
        :model="currentModel"
      />
      <v-op-params
        v-else-if="currentCategory === 3"
        ref="opParamsRef"
        :is-edit="isEdit"
        :model="currentModel"
      />
    </template>
    <div
      v-else
      class="__g-no-data"
    />
    <div
      v-if="isEdit"
      class="padding-primary-top display-flex display-flex-h-between"
    >
      <div />
      <yg-button
        type="primary"
        @click="_clickSubmit"
      >
        提交数据
      </yg-button>
    </div>
  </div>
</template>

<script>
import DeviceApiSet from '@/request/api/DeviceApiSet';
import DeviceOperateModel from '@/pages/050__deviceManager/chargingPileList/model/DeviceOperateModel';
import vPortPower from './operateSetting/portPower.vue';
import vCardMoney from './operateSetting/cardMoney.vue';
import vOpParams from './operateSetting/opParams.vue';

export default {
  components: { vPortPower, vCardMoney, vOpParams },
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      categoryOption: [
        { value: 1, label: '端口功率设置' },
        { value: 2, label: '刷卡金额设置' },
        { value: 3, label: '运营参数设置' }
      ],
      currentCategory: 1,
      isEdit: false,
      orgModel: {},
      currentModel: null
    };
  },
  created () {
    this.requestData();
  },
  methods: {
    _clickSubmit () {
      let refDom = null;
      if (this.currentCategory === 1) {
        refDom = this.$refs.portPowerRef;
      } else if (this.currentCategory === 2) {
        refDom = this.$refs.cardMoneyRef;
      } else {
        refDom = this.$refs.opParamsRef;
      }
      if (refDom.$checkSubmit().success) {
        if (this.currentCategory === 1) {
          const { success, msg, params } = this.currentModel.checkValidPowerSubmit();
          if (success) {
            this.requestPowerSubmit(params);
          } else {
            this.$Message.warning(msg);
          }
        } else {
          const { success, msg, params } = this.currentCategory === 2 ? this.currentModel.checkValidParameterSubmit(this.currentCategory) : this.currentModel.checkValidOperateParameterSubmit(this.currentCategory);
          if (success) {
            this.requestOperateSubmit(params);
          } else {
            this.$Message.warning(msg);
          }
        }
      }
    },
    _clickChangeEdit () {
      if (this.isEdit) {
        // 关闭编辑-先查看是否有变化,没有变化就直接改状态，有变化就要提示
        const oJson = JSON.stringify(this.orgModel);
        const dJson = JSON.stringify(this.currentModel);
        if (oJson === dJson) {
          this.isEdit = false;
        } else {
          this.$Modal.confirm({
            title: '温馨提示',
            content: '数据暂未提交，是否确认退出？',
            onOk: () => {
              this.currentModel = this.orgModel;
              this.isEdit = false;
            }
          });
        }
      } else {
        this.currentModel = DeviceOperateModel.deepCopy(this.orgModel);
        this.isEdit = true;
      }
    },
    onChangeRadio () {
      this.requestData();
    },
    // 获取-当前设备功率
    requestData () {
      this.currentModel = null;
      const api = DeviceApiSet.deviceChargeGet;
      api.params = {
        deviceId: this.model.id
      };
      this.$http(api).then(res => {
        const resData = res.data;
        resData.manufacturers = this.model.manufacturers;
        this.orgModel = new DeviceOperateModel();
        this.orgModel.initDataWithCategory(this.currentCategory, resData);
        this.currentModel = DeviceOperateModel.deepCopy(this.orgModel);
      });
    },
    // 提交-设备功率
    requestPowerSubmit (params) {
      const api = DeviceApiSet.deviceChargeSet;
      api.params = params;
      this.$http(api).then(res => {
        this.isEdit = false;
        this.$Message.success('操作成功');
        this.requestData();
        this.$emit('on-refresh');
      });
    },
    // 提交-运营设置
    requestOperateSubmit (params) {
      let api = null;
      // 需区分是结算功率还是刷卡充电币数量
      if (this.currentCategory === 2) {
        api = DeviceApiSet.swipeCardCostNumberSet; // 刷卡充电币数量
      } else {
        api = DeviceApiSet.deviceOperationType; // 运营参数
      }
      delete params.h5SetType;
      api.params = params;
      this.$http(api).then(res => {
        this.isEdit = false;
        this.$Message.success('操作成功');
        this.requestData();
        this.$emit('on-refresh');
      });
    }
  }
};
</script>
