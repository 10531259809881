// 超翔高级参数设置模型
import BaseModel from '@/libs/base/dataModel/BaseModel';
import { isValidNumber } from '@/libs/regularUtils';

export class ChaoXiangParamsModel1 extends BaseModel {
  constructor () {
    super();
    this._numChangeToString = true;
    this.maxPower = ''; // 最大功率

    // 标记是否为缓存中的数据
    this.h5IsStorageData = false;
    this.h5StorageTime = '';
  }

  initData (resData) {
    super.initData(resData);
    return this;
  }

  static checkValidSubmit (data) {
    const checkData = [
      { title: '最大功率', key: 'maxPower' }
    ];
    const res = checkData.find(v => !data[v.key] || !isValidNumber(data[v.key]));
    if (res && res.key) {
      return BaseModel.getValidRes(false, '请正确输入' + res.title + '，仅可输入大于0的正整数');
    }
    // 专项校验
    if (Object.keys(data).length <= 1) {
      return BaseModel.getValidRes(false, '请输入参数再提交');
    }
    return BaseModel.getValidRes(true, '', {
      maxPower: data.maxPower
    });
  }
}

export class ChaoXiangParamsModel2 extends BaseModel {
  constructor () {
    super();
    this._numChangeToString = true;
    this.fireMonitoring = ''; // 火灾检测是否禁用（0-禁用 1-启用）
    this.temperatureMonitoring = ''; // 温度检测是否禁用（0-禁用 1-启用）

    // 标记是否为缓存中的数据
    this.h5IsStorageData = false;
    this.h5StorageTime = '';
  }

  initData (resData) {
    super.initData(resData);
    return this;
  }

  static checkValidSubmit (data) {
    const checkData = [
      { title: '火灾检测是否禁用', key: 'fireMonitoring' },
      { title: '温度检测是否禁用', key: 'temperatureMonitoring' }
    ];

    const res = checkData.find(v => !data[v.key] || data[v.key] < 0);
    console.log(res);
    if (res && res.key) {
      return BaseModel.getValidRes(false, '请正确设置' + res.title);
    }

    return BaseModel.getValidRes(true, '', {
      fireMonitoring: data.fireMonitoring,
      temperatureMonitoring: data.temperatureMonitoring
    });
  }
}

export class ChaoXiangParamsModel3 extends BaseModel {
  constructor () {
    super();
    this._numChangeToString = true;
    this.openOnlineCard = ''; // 是否打开线上卡（0-关闭 1-打开）
    this.moveTime = ''; // 拔掉断电时间
    this.movePower = ''; // 拔掉断电功率
    this.stopTime = ''; // 充满自停时间
    this.stopPower = ''; // 充满自停功率
    this.emptyTime = ''; // 空载断电时间
    // 标记是否为缓存中的数据
    this.h5IsStorageData = false;
    this.h5StorageTime = '';
  }

  initData (resData) {
    super.initData(resData);
    this.h5OcDetectDisconnect = this.ocDetectDisconnect === '0';
    this.h5QrLight = this.qrLight === '0';
    return this;
  }

  static checkValidSubmit (data) {
    const checkData = [
      { title: '拔掉断电时间', key: 'moveTime' },
      { title: '拔掉断电功率', key: 'movePower' },
      { title: '充满自停时间', key: 'stopTime' },
      { title: '充满自停功率', key: 'stopPower' },
      { title: '空载断电时间', key: 'emptyTime' }
    ];
    const res = checkData.find(v => !data[v.key] || !isValidNumber(data[v.key]));
    if (res && res.key) {
      return BaseModel.getValidRes(false, '请正确输入' + res.title + '，仅可输入大于0的正整数');
    }
    // 专项校验
    if (!data.openOnlineCard) {
      return BaseModel.getValidRes(false, '请正确设置是否打开线上卡');
    }
    if (Object.keys(data).length <= 1) {
      return BaseModel.getValidRes(false, '请输入参数再提交');
    }
    return BaseModel.getValidRes(true, '', {
      openOnlineCard: data.openOnlineCard || '',
      moveTime: data.moveTime || '',
      movePower: data.movePower || '',
      stopTime: data.stopTime || '',
      stopPower: data.stopPower || '',
      emptyTime: data.emptyTime || ''
    });
  }
}
