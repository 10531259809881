
// 调整日志
export class VersionRecordListModel extends BaseModel {
  constructor () {
    super();
    this.adjustBeforeVersion = ''; // 调整前版本
    this.adjustAfterVersion = ''; // 调整后版本
    // 调整时间
    this.adjustTime = '';
    // 调整状态（-1:失败 0:调整中 1-成功）
    this.adjustStatus = '';
    // 调整状态描述
    this.adjustStatusDesc = '';
    // 备注
    this.remark = '';
    this.createUser = '';
  }

  initData (resData) {
    super.initData(resData);
    return this;
  }
}

// 可用版本列表
export class VersionEnableListModel extends BaseModel {
  constructor () {
    super();
    this.id = '';
    this.version = ''; // 版本号
    // 版本描述
    this.describe = '';
    // 末次更新时间
    this.updateTime = '';
  }

  initData (resData) {
    super.initData(resData);
    return this;
  }
}
