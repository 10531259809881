<!--
    Created by 程雨喵'mac on 2024/4/11.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    组件名称：选择任意文件
-->
<style lang="less">
.__g-choose-file {
  line-height: 16px;
  .import-area {
    position: relative;
    .up-file {
      padding: @containerGap * 1.5 @containerGap * 2;
      .display-flex();
      flex-direction: column;
      color: @textColorLight;
      font-size: 13px;
      font-weight: 600;
      background-color: @backColorLight;
      border-radius: 10px;
      border: 2px dashed @adornColor;
      text-align: center;
      img {
        margin-bottom: 10px;
        width: 60px;
      }
      .tip-text {
        white-space: nowrap;
        margin-top: 3px;
        font-size: 12px;
        font-weight: 300;
        &.has-data {
          color: @adornColor;
          font-weight: 400;
          .ivu-icon {
            cursor: pointer;
          }
        }
      }
    }
    .input-file {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }
    .ivu-icon-md-cloud-upload:before {
      margin-right: 6px;
    }
  }
}
</style>

<template>
  <div
    class="__g-choose-file"
  >
    <div class="import-area">
      <div class="up-file">
        <img src="/bg/file-icon.png">
        <div>{{ uploadFile && uploadFile.name ? '当前已选' : '请添加内容，可将内容拖动到这里！' }}</div>
        <div
          v-if="uploadFile && uploadFile.name"
          class="tip-text has-data"
        >
          {{ uploadFile.name }}
          <Icon
            type="ios-close-circle"
            @click.stop="_delFile"
          />
        </div>
        <div
          v-else
          class="tip-text"
        >
          请上传不大于1MB的内容
        </div>
      </div>
      <!-- 上传文件 -->
      <input
        v-if="!uploadFile"
        ref="uploadFile"
        class="input-file"
        type="file"
        @change="handUpload"
      >
    </div>
  </div>
</template>

<script>

export default {
  name: 'YgChooseFile',
  components: {},
  props: {
    templateParams: {
      type: Object,
      default: () => {
        return null;
      }
    },
    templateName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      uploadFile: null
    };
  },
  methods: {
    // 上传文件
    handUpload (event) {
      const files = event.target.files || event.dataTransfer.files;
      console.log(files[0]);
      if (!files.length) {
        this.clearImportFile();
        return this.$Message.warning('请上传内容');
      } else if (files[0].size > 1024000) {
        // this.clearImportFile();
        return this.$Message.warning('内容不可大于1MB');
      } else if (files[0].name.includes('/')) {
        return this.$Message.warning('文件名请不要包含反斜杠/');
      }
      this.uploadFile = files[0];
      this.clearImportFile();
      this.$Message.info('已选择：' + this.uploadFile.name);
      this.$emit('on-change', this.uploadFile);
    },
    // 删除
    _delFile () {
      this.uploadFile = null;
      this.clearImportFile();
      this.$emit('on-change', null);
    },
    // 清空导入文件file
    clearImportFile () {
      this.$refs.uploadFile.files = null;
      this.$refs.uploadFile.value = null;
    }
  }
};
</script>
