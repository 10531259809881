
export default {
  data () {
    return {
      tableColumns: [
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('deviceCategoryName', '设备类别').setSpan({}),
        BaseTableColumn.initData('portCategoryName', '端口类别').setSpan({}),
        BaseTableColumn.initData('manufacturersName', '生产\n厂商名称').setSpan({}),
        BaseTableColumn.initData('deviceModel', '设备\n型号编码').setSpan({ canCopy: true }),
        BaseTableColumn.initData('deviceName', '设备\n型号名称').setSpan({ canCopy: true }),
        BaseTableColumn.initData('iotInfo', '物联网').setSpan({}),
        BaseTableColumn.initData('modeCode', '厂商\n型号代码').setSpan({ canCopy: true }),
        BaseTableColumn.initData('h5StatusSwitch', '状态').setSwitch((e, row, index) => {
          row.h5StatusSwitch = e;
          this.$Modal.confirm({
            title: '操作提示',
            content: `是否确认${e ? '启用' : '停用'}`,
            onOk: () => {
              this.requestChangeStatus(row, e);
            },
            onCancel: () => {
              row.h5StatusSwitch = !e;
              this.$Message.info('操作已取消');
            }
          });
        }, 'system:deviceType:status'),
        BaseTableColumn.initData('setting', '操作').setWidth(120).setAlign('center').setButtons([
          { title: '编辑', name: 'edit', permission: 'system:deviceType:edit' },
          { title: '软件版本', name: 'version', permission: 'system:device:getDeviceVersions' }
        ], ({ name }, row, index) => {
          switch (name) {
          case 'edit':
            this.requestInfo(row.id);
            break;
          case 'version':
            this.dialog.versionModel = row;
            break;
          }
        })
      ]
    };
  }
};
