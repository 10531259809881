<!--
    Created by 王丽莎 on 2024/08/29.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：运营管理-充电桩分账模版
-->
<style lang="less">

</style>

<template>
  <div id="separate-accounts-template">
    <div v-permission="'system:admin:template:qryAllocateTemplateList'">
      <yg-search-area
        :show-info="false"
        :value="searchOption"
        @on-search="_clickSearch"
      />
    </div>
    <div>
      <yg-setting-area
        :items="settingItems"
        :total="page.total"
        @on-click="_clickSetting"
      />
    </div>
    <div
      v-permission="'system:admin:template:qryAllocateTemplateList'"
      class="padding-primary-top"
    >
      <yg-table
        :columns="tableColumns"
        :data="tableData"
        :page="page"
        @on-page-change="onChangePage"
      />
    </div>
    <!--模版下发记录-->
    <v-record
      v-if="dialog.recordModel"
      @on-close="dialog.recordModel = false"
    />
    <!--新增模板-->
    <v-add
      v-if="dialog.addModel"
      :model="dialog.addModel"
      @on-close="onCloseAddDialog"
    />
    <!--下发模板-->
    <v-issue
      v-if="dialog.issueModel"
      :model="dialog.issueModel"
      @on-close="onCloseIssueDialog"
    />
  </div>
</template>

<script>
import OperationsApiSet from '@/request/api/OperationsApiSet';
import { SeparateAccountsListModel, SeparateAccountsDetailModel } from './model/SeparateAccountsDataModel';
import ColumnMixin from '@/pages/108__operationsManager/separateAccountsTemplate/mixin/tableColumn';
import vRecord from './view/record.vue';
import vAdd from './view/submit/add.vue';
import vIssue from './view/submit/issue.vue';

export default {
  name: 'SeparateAccountsTemplate',
  components: { vRecord, vAdd, vIssue },
  mixins: [ColumnMixin],
  props: {},
  data () {
    return {
      searchOption: {
        templateName: BaseSearchModel.initData('模版名称', 'templateName', 'Input'),
        category: BaseSearchModel.initData('分账方式', 'category', 'Select').setData([
          { label: '按比例分账', value: 1 },
          { label: '按电费服务费分账', value: 2 }
        ])
      },
      settingItems: [
        BaseSettingModel.initData('新增模版', 'add', '').setPermission('system:admin:template:addAllocateTemplate'),
        BaseSettingModel.initData('模版下发记录', 'record', '').setPermission('system:admin:template:issueAllocateRecord'),
        BaseSettingModel.initData('刷新列表', 'refresh', 'md-refresh-circle').setPermission('system:admin:template:qryAllocateTemplateList')
      ],
      page: { current: 1, total: 0 },
      tableData: [],
      tableSort: null,
      dialog: {
        recordModel: null,
        addModel: null,
        issueModel: null
      }
    };
  },
  created () {},
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    onChangePage (page, sortDic) {
      this.page.current = page;
      if (sortDic) {
        this.tableSort = sortDic;
      }
      this.requestData();
    },
    onCloseAddDialog (needRefresh) {
      if (needRefresh) {
        this.onChangePage(1);
      }
      this.dialog.addModel = false;
    },
    onCloseIssueDialog (goToRecord) {
      this.dialog.issueModel = null;
      this.dialog.recordModel = goToRecord;
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    _clickSearch (option) {
      this.searchOption = option;
      this.page.current = 1;
      this.requestData();
    },
    _clickSetting (key) {
      switch (key) {
      case 'refresh':
        this.page.current = 1;
        this.requestData();
        break;
      case 'add':
        this.dialog.addModel = SeparateAccountsDetailModel.createAdditionModel();
        break;
      case 'record':
        this.dialog.recordModel = true;
        break;
      }
    },
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    // 请求列表
    requestData () {
      this.tableData = [];
      this.page.total = 0;
      const api = OperationsApiSet.qryAllocateTemplateList;
      const s = this.searchOption;
      api.params = {
        timeAsc: '', // 排序在下面赋值，判断tableSort变量
        templateName: s.templateName.value,
        category: s.category.value,
        pageSize: this.$config.pageSize,
        pageNo: this.page.current
      };
      if (this.tableSort) {
        api.params[this.tableSort.sortReqKey] = this.tableSort.sortReqValue;
      }
      this.$http(api).then(res => {
        const resData = res.data;
        this.page.total = resData.total;
        this.tableData = SeparateAccountsListModel.initListWithDataList(resData.rows);
        // const resData = SeparateAccountsListModel.createFakeDataList();
        // this.tableData = SeparateAccountsListModel.initListWithDataList(resData);
      });
    },
    // 请求详情
    requestDetail (templateId, key) {
      // copy, issue
      const api = OperationsApiSet.qryAllocateTemplateDetail;
      api.params = {
        templateId
      };
      this.$http(api).then(res => {
        this.dialog[key] = SeparateAccountsDetailModel.initModelWithData({ ...res.data, ...{ templateId } });
      });
    },
    // 请求删除
    requestDel (templateId) {
      const api = OperationsApiSet.deleteAllocateTemplate;
      api.params = {
        templateId
      };
      console.log(api);
      this.$http(api).then(res => {
        this.$Message.success('操作成功');
        this.onChangePage(1);
      });
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
