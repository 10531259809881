<!--
    Created by 程雨喵'mac on 2024/11/29.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：高渲染性能的table(并且支持header多行)，原生的table组件
-->
<style lang="less">
.__g-simple-table {
  position: relative;
  overflow: auto;
  //padding-top: 1px;
  padding-left: 1px;
  background-color: @lineColorNormal;
  box-sizing: border-box;
  .has-border-top {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    height: 1px;
    border-top: 1px solid @lineColorNormal;
  }
  .has-border-right {
    content: '';
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    width: 1px;
    border-right: 1px solid @lineColorNormal;
  }
  .__g-table-util-box {
    position: sticky;
    display: flex;
    align-items: center;
    //justify-content: center;
    top: 0;
    height: 24px;
    z-index: 1;
    background-color: @backColorLight;
    //border: 1px solid @lineColorNormal;
    border-bottom: 0;
    box-sizing: border-box;
    font-size: 12px;
    color: @textColorLight;
    border-top: 1px solid @lineColorNormal;
    &::before {
      .has-border-top();
    }
    &::after {
      .has-border-right();
    }
    .util-tit {
      flex: 1;
      text-align: center;
      padding: 0 10px;
      font-weight: 600;
      line-height: 24px;
      height: 24px;
      color: @textColorNormal;
    }
    .normal-utils {
      position: absolute;
      right: 0;
      display: flex;
      align-items: center;
      color: @adornColor;
      .util-item {
        .display-flex();
        margin-right: @containerGap * 0.5;
        padding: 0 7px;
        cursor: pointer;
        border: 1px solid @adornColor;
        line-height: 16px;
        border-radius: 4px;
        &.util-is-export {
          background-color: @adornColor;
          color: white;
        }
        &:hover {
          opacity: 0.8;
        }
        .ivu-icon {
          margin-right: 5px;
          font-size: 12px;
        }
      }
    }
    .current-util {
      position: absolute;
      right: 10px;
      .display-flex();
      color: @adornColor;
      .btn {
        margin-left: 5px;
        padding: 0 2px;
        line-height: 12px;
        border: 1px solid @adornColor;
        border-radius: 2px;
        font-size: 11px;
        color: @adornColor;
        cursor: pointer;
      }
    }
  }
  .__g-simple-table-inner {
    width: 100%;
    margin: 0;
    border-collapse: collapse;
    //border-collapse: separate;
    .__g-simple-table-th, .__g-simple-table-td {
      //border: 1px solid @lineColorNormal;
      text-align: center;
      padding: 5px 3px 6px 2px;
      font-size: 12px;
      line-height: 12px;
      position: relative;
      &::before {
        .has-border-top();
      }
      &::after {
        .has-border-right();
      }
    }
    .__g-simple-table-header {
      position: sticky;
      top: 24px;
      z-index: 1;
      background-color: @backColorStrong;
      border: none;
      color: @textColorLight;
      white-space: nowrap;
      .tb-header-row {
        color: @textColorNormal;
      }
      .__g-simple-table-th {
        &.__g-col-merge {
          background-color: @backColorNormal;
        }
        .th-cell {
          .display-flex();
          width: 100%;
          height: 100%;
          .sort-header-box {
            position: relative;
            display: inline-flex;
            margin-left: -3px;
            width: 10px;
            height: 16px;
            .ivu-icon-md-arrow-dropup {
              position: absolute;
              top: -3px;
              font-size: 16px;
              &.is-act { color: @adornColor }
            }
            .ivu-icon-md-arrow-dropdown {
              position: absolute;
              bottom: -3px;
              font-size: 16px;
              &.is-act { color: @adornColor }
            }
          }
        }
        &.can-click {
          cursor: pointer;
          &:hover {
            color: @themeColor;
          }
        }
      }
    }
    .__g-simple-table-body {
      .__g-simple-table-tr {
        background-color: @backColorStrong;
        //&:nth-of-type(2n - 1) {
        //  background-color: @backColorNormal;
        //}
        .__g-simple-table-td {
          //background-color: @backColorStrong;
          &.__g-col-merge {
            background-color: @backColorNormal;
            //background-color: rgba(84,110,253,0.02);
          }
        }
      }
    }
  }
  .__g-simple-table-no-data {
    border-bottom: 1px solid @lineColorNormal;
    border-right: 1px solid @lineColorNormal;
  }
}
// 重写iview-page的样式
.simple-table-pager {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  margin-top: 10px;
  font-size: 10px;
  height: 24px;
  @itemHeight: 22px;
  box-sizing: border-box;
  .ivu-page.__g-simple-pager {
    .ivu-page-total {
      line-height: @itemHeight;
      height: @itemHeight;
    }
    .ivu-page-item, .ivu-page-prev, .ivu-page-next {
      margin-right: 10px;
      border-radius: @borderRadiusMin !important;
      border-color: @lineColorNormal;
      line-height: @itemHeight - 2px;
      min-width: @itemHeight;
      height: @itemHeight;
      background-color: @backColorStrong;
      color: @textColorNormal;
      &.ivu-page-item-active {
        color: @textColorFlip;
        background-color: @themeColor;
        //border-color: @themeColor;
        a {
          color: @textColorFlip !important;
        }
      }
      a {
        color: @textColorNormal;
      }
      &:hover {
        color: @themeColor;
        border-color: @themeColor;
        a {
          color: @themeColor;
        }
      }
    }
    .ivu-page-options-elevator {
      input {
        border-radius: @borderRadiusMin !important;
        border-color: @lineColorNormal;
        height: @itemHeight;
        &:hover {
          border-color: @themeColor;
        }
        &:focus {
          border-color: @themeColor;
        }
      }
    }
  }
}
</style>

<template>
  <div class="__g-simple-table-outer">
    <div
      ref="globalSimpleTable"
      :style="`max-height:${tableHeight}px`"
      class="__g-simple-table"
    >
      <div class="__g-table-util-box">
        <div class="util-tit">
          {{ topTitle }}
        </div>
        <!--常态展示-->
        <template
          v-if="showControl"
        >
          <div
            v-if="!currentUtil"
            class="normal-utils"
          >
            <div
              v-for="(item, i) in tableUtils"
              :key="'utils-' + i"
              class="util-item"
              @click.stop="_clickUtil(i)"
            >
              <Icon :type="item.icon" />
              <span>{{ item.label }}</span>
            </div>
            <div
              v-if="exportSetting"
              v-permission="exportSetting.permission"
              class="util-item util-is-export"
              @click.stop="_clickExport"
            >
              <Icon type="md-cloud-download" />
              <span>导出全量列表</span>
            </div>
          </div>
          <!--选择某个工具后展示-->
          <div
            v-else-if="currentUtilItem"
            class="current-util"
          >
            <Icon :type="currentUtilItem.icon" />
            <span>{{ currentUtilItem.label }}:</span>
            <div
              class="btn"
              @click.stop="_clickFinishUtil"
            >
              完成操作
            </div>
          </div>
        </template>
      </div>
      <table
        class="__g-simple-table-inner"
      >
        <thead class="__g-simple-table-header">
          <!--第一行合并标题-->
          <tr class="tb-header-row">
            <th
              v-for="(col, c) in currentTopColumns"
              :key="'top-th-' + c"
              :colspan="col.h5Colspan"
              class="__g-simple-table-th"
              :class="col.className"
            >
              {{ col.title }}
              <span v-if="currentUtil === 'col'">
                (
                <Icon
                  type="ios-eye"
                  style="font-size: 13px"
                  @click.stop="_clickShowAll(true, c)"
                />
                |
                <Icon
                  type="ios-eye-off-outline"
                  @click.stop="_clickShowAll(false, c)"
                />
                )
              </span>
            </th>
          </tr>
          <!--第二行标题-->
          <tr>
            <th
              v-for="(col, c) in currentColumns"
              :key="'th-' + c"
              class="__g-simple-table-th"
              :class="(currentUtil ? 'can-click' : '') + ' ' + col.className"
              @click.stop="_clickColTitle(col.key)"
            >
              <div class="th-cell">
                <span
                  v-if="!col.h5HeaderColumnTooltip"
                  class="ht-cell-text"
                >{{ col.title }}</span>
                <Poptip
                  v-else
                  trigger="hover"
                  transfer
                >
                  <span
                    class="display-flex display-flex-v-center"
                  >
                    {{ col.title }}
                    <Icon type="ios-help-circle" />
                  </span>
                  <span slot="content">{{ col.h5HeaderColumnTooltip }}</span>
                </Poptip>
                <span v-if="currentUtil === 'col'">
                  <Icon
                    v-if="getColShow(col.key)"
                    type="ios-eye"
                    style="font-size: 13px"
                  />
                  <Icon
                    v-else
                    type="ios-eye-off-outline"
                  />
                </span>
                <span
                  v-else-if="currentUtil === 'sort' && col.key !== 'index'"
                  class="sort-header-box"
                >
                  <Icon
                    type="md-arrow-dropup"
                    :class="sort.key === col.key && sort.type === 1 ? 'is-act' : ''"
                  />
                  <Icon
                    type="md-arrow-dropdown"
                    :class="sort.key === col.key && sort.type === 0 ? 'is-act' : ''"
                  />
                </span>
              </div>
            <!--<span></span>-->
            </th>
          </tr>
        </thead>
        <tbody
          v-if="currentData.length"
          class="__g-simple-table-body"
        >
          <tr
            v-for="(row, r) in currentData"
            :key="'tr-' + r"
            class="__g-simple-table-tr"
          >
            <td
              v-for="(col, c) in currentColumns"
              :key="'td-' + c"
              class="__g-simple-table-td"
              :class="col.className"
            >
              <span v-if="col.key === 'index'">{{ r + 1 }}</span>
              <span v-else-if="!col.render">{{ row[col.key] || (row[col.key] === 0 ? 0 : '-') }}</span>
              <v-table-cell-render
                v-else
                :render="col.render"
                :column="col"
                :row="row"
                :index="r"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-if="currentData.length === 0"
        class="__g-simple-table-no-data"
      >
        <div class="__g-no-data" />
      </div>
    </div>
    <div
      v-if="showPager && page.total > pageSize"
      class="simple-table-pager"
    >
      <Page
        v-if="!currentUtil"
        :current="page.current"
        :total="page.total"
        :page-size="pageSize"
        :show-total="true"
        :show-elevator="false"
        class-name="__g-simple-pager"
        @on-change="_pageChange"
      />
    </div>
  </div>
</template>

<script>
import { onHandler, offHandler, getElementTop, sortBy } from '@/libs/utils.js';
import vTableCellRender from './cell-render';
import DownloadApiSet from '@/request/api/DownloadApiSet';
export default {
  name: 'YgSimpleTable',
  components: { vTableCellRender },
  props: {
    // 外部可以通过这个属性的变化来控制table重新 触发resize事件
    controlResize: {
      type: Boolean,
      default: false
    },
    // 最顶部的header
    topColumns: {
      type: Array,
      default: () => {
        return [];
      }
    },
    columns: {
      type: Array,
      default: () => {
        return [];
      }
    },
    data: {
      type: Array,
      default: () => {
        return [];
      }
    },
    // -1则展示数据内容高度，无滚动条
    maxHeight: {
      type: Number,
      default: 0
    },
    // 默认底部减去的偏移量
    bottomOffset: {
      type: Number,
      default: 0
    },
    /**
     * 是否展示分页
     * 默认为展示
     */
    showPager: {
      type: Boolean,
      default: true
    },
    /**
     * page相关3件套
     */
    page: {
      type: Object,
      default: () => {
        return { current: 1, total: 0 };
      }
    },
    pageSize: {
      type: Number,
      default: 200
    },
    showControl: {
      type: Boolean,
      default: true
    },
    // 缓存表头的列时使用，会作为key保存表头数组，没有值的时候会使用router.name
    storageColumnKey: {
      type: String,
      default: ''
    },
    needStorageColumnKey: {
      type: Boolean,
      default: true
    },
    topTitle: {
      type: String,
      default: '详细信息'
    },
    // 全量导出按钮配置，如果没有值就表示不需要导出(把BaseSettingModel传进来)
    exportSetting: {
      type: Object,
      default: () => {
        return null;
      }
    },
    // 与全量导出的配合一起使用，需要把筛选条件传过来
    exportCondition: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      tableHeight: 0,
      tableUtils: [
        {
          label: '表格列控制',
          key: 'col',
          icon: 'md-eye'
        },
        { label: '当前页表格排序', key: 'sort', icon: 'ios-stats' }
        // { label: '数据求和', key: 'sum', icon: 'md-add' }
      ],
      currentUtilIndex: -1,
      // 控制列的展示(里面存需要展示的key)
      showColumnsKey: [],
      currentTopColumns: [],
      currentColumns: [],
      currentData: [],
      // 排序
      sort: {
        key: '',
        // 1: 升序(ASC)，0：降序(DESC)
        type: 0
      }
    };
  },
  computed: {
    currentUtil () {
      if (this.currentUtilIndex === -1) {
        return '';
      }
      return this.tableUtils[this.currentUtilIndex].key;
    },
    currentUtilItem () {
      if (this.currentUtilIndex === -1) {
        return null;
      }
      return this.tableUtils[this.currentUtilIndex];
    }
  },
  watch: {
    $route (o, n) {
      if (o && n && o.name !== n.name) {
        this.handleResize();
      }
    },
    data () {
      this.handleResize();
      this.sortDataList();
    },
    controlResize () {
      this.handleResize();
    }
  },
  created () {
    this.getStorageColumns();
    this.resetColumns();
    this.sortDataList();
  },
  mounted () {
    this.$nextTick(() => {
      this.handleResize();
      onHandler(window, 'resize', this.handleResize);
    });
  },
  destroyed () {
    offHandler(window, 'resize', this.handleResize);
  },
  methods: {
    getStorageColumns () {
      const arr = this.needStorageColumnKey ? (this.$storage.dataAnalysisColumn[this.storageColumnKey || this.$route.name]) : [];
      if (arr && arr.length) {
        this.showColumnsKey = arr;
      } else {
        const showColumnsKey = [];
        const columns = this.columns;
        columns.forEach(v => {
          showColumnsKey.push(v.key);
        });
        this.showColumnsKey = showColumnsKey;
      }
    },
    setStorageColumns () {
      this.$storage.dataAnalysisColumn = {
        key: this.storageColumnKey || this.$route.name,
        data: JSON.parse(JSON.stringify(this.showColumnsKey))
      };
    },
    resetColumns (showAll = false) {
      const columns = this.columns;
      const showColumnsKey = this.showColumnsKey;
      const currentColumns = [];
      columns.forEach(v => {
        // 重置列合并的颜色
        v.className = '';
        // 全部展示
        if (showAll) {
          currentColumns.push(v);
        } else {
          if (showColumnsKey.includes(v.key)) {
            currentColumns.push(v);
          }
        }
      });
      const currentTopColumns = [];
      if (this.topColumns && this.topColumns.length) {
        // 当前的下标
        let sIndex = 0;
        for (let i = 0; i < this.topColumns.length; i++) {
          const topCol = this.topColumns[i];
          const colspan = parseInt(topCol.h5Colspan);
          // 获取区间内的 列
          const rangeColArr = this.columns.slice(sIndex, sIndex + colspan);

          // 全部展示
          if (showAll) {
            currentTopColumns.push({
              title: topCol.title,
              h5Colspan: colspan + ''
            });
          } else {
            let newColspan = 0;
            const mergeKeyArr = [];
            for (let j = 0; j < rangeColArr.length; j++) {
              const rangeItem = rangeColArr[j];
              if (showColumnsKey.includes(rangeItem.key)) {
                newColspan += 1;
                mergeKeyArr.push(rangeItem.key);
              }
            }
            if (newColspan > 0) {
              const dic = {
                title: topCol.title,
                mergeKeyArr: [],
                h5Colspan: newColspan + ''
              };
              // 每隔一列加颜色
              if (currentTopColumns.length % 2 === 0) {
                dic.className = '__g-col-merge';
                dic.mergeKeyArr = mergeKeyArr;
              }
              currentTopColumns.push(dic);
            }
          }
          sIndex += colspan;
        }
      }
      // 重新赋值 列合并的颜色
      currentTopColumns.forEach(v => {
        if (v.className && v.mergeKeyArr.length) {
          currentColumns.forEach(vv => {
            if (v.mergeKeyArr.includes(vv.key)) {
              vv.className = '__g-col-merge';
            }
          });
        }
      });
      this.currentColumns = currentColumns;
      this.currentTopColumns = currentTopColumns;
    },
    sortDataList () {
      this.$ygLoading.show();
      setTimeout(() => {
        const { key, type } = this.sort;
        if (!key) {
          this.currentData = this.data.slice(0, this.data.length + 1);
          this.$ygLoading.close();
          return;
        }
        const filter = this.data.slice(0, this.data.length + 1);
        this.currentData = filter.sort(sortBy(key, type));
        this.$ygLoading.close();
      }, 50);
    },
    handleResize () {
      if (this.maxHeight === -1) {
        this.tableHeight = 9999;
        return;
      }
      // 这里加延迟是应为团餐项目中的search-area高度是延迟赋值的，因此table的顶部y获取不准，别的项目不要加延迟
      setTimeout(() => {
        const currentRowTable = this.$refs.globalSimpleTable;
        const top = getElementTop(currentRowTable);
        // 窗口高度 - table的y - 底部page的高度 - 1(减一是因为如果不是整数会四舍五入) - 内容区域距离浏览器底部的间距
        let tableHeight = window.innerHeight - top - 1 - 15 * 2 - this.bottomOffset;
        if (this.showPager && (this.page.total > this.pageSize)) {
          // 减顶部margin与高度34
          tableHeight = tableHeight - 10 - 24 - 3;
        }
        this.tableHeight = tableHeight;
      }, 100);
    },
    _pageChange (page) {
      this.$emit('on-page-change', page);
    },
    // 查看列是否可见
    getColShow (key) {
      return this.showColumnsKey.includes(key);
    },
    // 点击标题
    _clickColTitle (colKey) {
      const key = this.currentUtil;
      if (!key) return;
      if (key === 'col') {
        // 列隐藏/展示
        const isShow = this.showColumnsKey.includes(colKey);
        if (isShow) {
          // 当前为-正在展示(需要改为 隐藏)
          this.showColumnsKey = this.showColumnsKey.filter(v => v !== colKey);
        } else {
          // 当前为-正在隐藏(需要改为 展示)
          this.showColumnsKey.push(colKey);
        }
      } else if (key === 'sort') {
        if (this.sort.key === colKey) {
          this.sort.type = 1 - this.sort.type;
        } else {
          this.sort.type = 0;
        }
        this.sort.key = colKey;
        this.sortDataList();
        // this.currentUtilIndex = -1;
        // console.log(this.sort);
      }
    },
    // 点击一键展示与隐藏
    _clickShowAll (value, index) {
      // 当前的下标
      let sIndex = 0;
      for (let i = 0; i < this.topColumns.length; i++) {
        const topCol = this.topColumns[i];
        const colspan = parseInt(topCol.h5Colspan);
        if (i === index) {
          const rangeColArr = this.columns.slice(sIndex, sIndex + colspan);
          rangeColArr.forEach(vItem => {
            if (value) {
              // 展示
              if (!this.showColumnsKey.includes(vItem.key)) {
                this.showColumnsKey.push(vItem.key);
              }
            } else {
              // 移除
              this.showColumnsKey = this.showColumnsKey.filter(v => v !== vItem.key);
            }
          });
        }
        sIndex += colspan;
      }
    },
    // 点击顶部工具
    _clickUtil (index) {
      this.$ygLoading.show();
      setTimeout(() => {
        const { key } = this.tableUtils[index];
        if (key === 'col') {
          this.currentData = [];
          this.resetColumns(true);
        }
        this.currentUtilIndex = index;
        this.$ygLoading.close();
      }, 50);
    },
    _clickFinishUtil () {
      this.$ygLoading.show();
      setTimeout(() => {
        const { key } = this.tableUtils[this.currentUtilIndex];
        if (key === 'col') {
          this.resetColumns();
          // 列的时候保存 key
          this.setStorageColumns();
          // 重新渲染
          this.sortDataList();
        }
        this.currentUtilIndex = -1;
        this.$ygLoading.close();
      }, 50);
    },
    _clickExport () {
      if (this.page.total <= 0) {
        return this.$Modal.warning({
          title: '无数据可导出',
          content: '请先查询到结果后再进行导出',
          okText: '我知道了'
        });
      }
      const api = DownloadApiSet.downloadCreate;
      const reportCondition = JSON.parse(JSON.stringify(this.exportCondition));
      api.params = {
        reportType: this.exportSetting.exportReportType,
        reportCondition
      };
      this.$http(api).then(res => {
        this.$store.commit('setDownloadAmount', +1);
        this.$Modal.confirm({
          title: '操作成功',
          content: '请至下载中心导出',
          okText: '立即前往',
          cancelText: '稍后前往',
          onOk: () => {
            this.$router.push({
              name: 'download_list'
            });
          }
        });
      });
    }
  }
};
</script>
