export default {
  data () {
    return {
      tableColumns1: [
        BaseTableColumn.initData('adjustAfterVersion', '调整后版本', 80).setSpan({}),
        BaseTableColumn.initData('adjustBeforeVersion', '调整前版本', 80).setSpan({}),
        BaseTableColumn.initData('adjustTime', '调整时间').setSpan({}),
        BaseTableColumn.initData('adjustStatus', '状态').setWidth(80).setStatus([
          { value: -1, type: 1 },
          { value: 0, type: 4 },
          { value: 1, type: 2 }
        ], 'adjustStatusDesc'),
        BaseTableColumn.initData('remark', '备注').setSpan({}),
        BaseTableColumn.initData('createUser', '操作人').setSpan({ canTooltip: true })
      ],
      tableColumns2: [
        BaseTableColumn.initData('version', '版本号').setWidth(80).setSpan({}),
        BaseTableColumn.initData('updateTime', '末次更新日期', 100).setSpan({}),
        BaseTableColumn.initData('describe', '版本描述').setSpan({ canTooltip: true }),
        BaseTableColumn.initData('setting', '操作').setWidth(120).setAlign('center').setButtons([
          { title: '设为当前版本', name: 'version', permission: '' }
        ], ({ name }, row, index) => {
          if (name === 'version') {
            this.$Modal.confirm({
              title: '操作提示',
              content: '是否确认操作？',
              onOk: () => {
                this.requestChangeVersion(row.id);
              }
            });
          }
        })
      ]
    };
  }
};
