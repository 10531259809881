// import { isValidString1, isValidString2 } from '@/libs/regularUtils';

export class DeviceTypeVersionModel extends BaseModel {
  constructor () {
    super();
    this.id = '';
    // 版本号
    this.version = '';
    // 指令内容
    this.instructions = '';
    // 升级包
    this.packageUrl = '';
    // 版本描述
    this.describe = '';
    // 端口类别
    this.updateTime = '';
    // 操作人
    this.updateBy = '';

    this.file = null;
    // 下面三个新增时使用 --------------->
    this.manufacturers = '';
    this.deviceCategory = '';
    this.portCategory = '';
    // 上面三个新增时使用 --------------->
    // 编辑时记录是否移除原有的file（packageUrl）
    this.h5IsDelPackageUrl = false;
    // 列表中用于展示的升级包
    this.h5ShowPackageUrl = '';
  }

  initData (resData) {
    super.initData(resData);
    const arr = this.packageUrl.split('/');
    this.h5ShowPackageUrl = arr[arr.length - 1];
    return this;
  }

  static createFakeDataList (len = 30) {
    const list = [];
    for (let i = 0; i < len; i++) {
      const dic = {
        id: i + 100,
        version: BaseModel.getFakeTitle(Math.ceil(Math.random() * 10)),
        instructions: '000101010' + i,
        packageUrl: 'www.baiud.com',
        describe: BaseModel.getFakeTitle(Math.ceil(Math.random() * 50)),
        updateTime: '2024-12-10',
        updateBy: 'admin'
      };
      list.push(dic);
    }
    return list;
  }

  /**
   * 数据具体校验-新增/编辑设备类型
   * @returns {*}
   */
  checkValidSubmit () {
    // if (!isValidString1(this.version)) {
    //   return BaseModel.getValidRes(false, '版本号中存在无效字符');
    // }
    // else if (!isValidString1(this.describe)) {
    //   return BaseModel.getValidRes(false, '版本描述中存在无效字符');
    // }
    // if (this.instructions) {
    //   if (!isValidString2(this.instructions)) {
    //     return BaseModel.getValidRes(false, '指令内容中存在无效字符');
    //   }
    // }

    const formDt = new FormData();
    formDt.append('file', this.file);
    formDt.append('version', this.version);
    formDt.append('describe', this.describe);
    formDt.append('instructions', this.instructions);
    if (this.id) {
      // 编辑
      formDt.append('id', this.id);
      if (!this.file && this.h5IsDelPackageUrl) {
        // 原来有url，且当前没有file时就是删除
        formDt.append('fileOperation', '1');
      }
    } else {
      // 新增
      formDt.append('deviceCategory', this.deviceCategory);
      formDt.append('manufacturers', this.manufacturers);
      formDt.append('portCategory', this.portCategory);
    }

    return BaseModel.getValidRes(true, '', formDt);
  }

  static createAdditionModel ({ manufacturers, deviceCategory, portCategory }) {
    const model = new this();
    model.manufacturers = manufacturers;
    model.deviceCategory = deviceCategory;
    model.portCategory = portCategory;
    return model;
  }
}
