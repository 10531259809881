import BaseModel from '@/libs/base/dataModel/BaseModel';
import { isNumber, isFloat } from '@/libs/regularUtils';

// manufacturers 对应的 端口功率字段
const portManuFacturesDic = (function () {
  const dic = {};
  dic['10'] = 'portPowerCX';
  dic['40'] = 'portPowerYD';
  dic['50'] = 'portPowerSY';
  return dic;
})();
// manufacturers 对应的 刷卡金额字段
const cardManuFacturesDic = (function () {
  const dic = {};
  dic['10'] = 'swipeCardCX';
  dic['40'] = 'swipeCardYD';
  dic['50'] = 'swipeCardSY';
  return dic;
})();
export default class DeviceOperateModel extends BaseModel {
  constructor () {
    super();
    // 设备列表中传过来(可以用这个字段判断是否是设备中使用还是从下发模板中使用)
    this.manufacturers = ''; //  10-超翔 20-山野 30-小橙 40-友电 50-尚亿
    // 运营参数相关设置 --------------- start >
    this.deviceId = ''; // 运营类型 0-自有、1-共建、2-外拓
    this.operationType = null; // 运营类型 0-自有、1-共建、2-外拓
    this.operationTypeDesc = ''; // 运营类型描述
    this.freeConfig = null; // 0->关闭、1->开启
    this.fictitiousPower = null; // 结算功率虚位
    // 最大充电比例开关 0关闭 1打开
    this.maxChargePercentSwitch = 0;
    // 最大充电比例
    this.maxChargePercent = null;
    // 线损比例开关 0关闭 1打开
    this.powerLossPercentSwitch = 0;
    // 线损比例
    this.powerLossPercent = null;
    // 最低消费电量
    this.minUseKwh = null;

    // 订单最大功率开关 0关闭 1打开
    this.orderMaxPowerSwitch = 0;
    // 订单最大功率（时长计费模式下生效）
    this.orderMaxPower = null;
    // 设备统一状态(模板中使用)
    this.deviceStatus = null;
    // 远程开启充电
    this.remoteActivaSwich = null; // 0->关闭、1->开启
    // 远程结束充电
    this.remoteShutdownSwich = null; // 0->关闭、1->开启
    // 运营参数相关设置 --------------- end >

    // 刷卡扣币参数相关设置(兼容设备以及模板下发) --------------- start >
    this.swipeCardCX = null; // 超翔
    this.swipeCardYD = null; // 友电
    this.swipeCardSY = null; // 尚亿
    // 刷卡扣币参数相关设置(兼容设备以及模板下发) --------------- end >

    // 最大功率相关设置(兼容设备以及模板下发) --------------- start >
    this.portPowerCX = null; // 超翔
    this.portPowerYD = null; // 友电
    this.portPowerSY = null; // 尚亿
    // 最大功率相关设置(兼容设备以及模板下发) --------------- end >
  }

  get h5MaxChargePercentDesc () {
    if (this.maxChargePercentSwitch === 1) {
      return `已开启(${this.maxChargePercent || '-'}%)`;
    }
    return '关闭';
  }

  get h5PowerLossPercentDesc () {
    if (this.powerLossPercentSwitch === 1) {
      return `已开启(${this.powerLossPercent || '-'}%)`;
    }
    return '关闭';
  }

  get h5OrderMaxDesc () {
    if (this.orderMaxPowerSwitch === 1) {
      return `已开启(${this.orderMaxPower || '-'}瓦)`;
    }
    return '关闭';
  }

  get h5FreeConfigDesc () {
    if (this.freeConfig === 0) {
      return '关闭';
    } else if (this.freeConfig === 1) {
      return '开启';
    }
    return '-';
  }

  get h5DeviceStatusDesc () {
    if (this.deviceStatus === 0) {
      return '设备全部关闭';
    } else if (this.deviceStatus === 1) {
      return '设备全部开启';
    }
    return '-';
  }

  get h5RemoteShutdownSwichDesc () {
    if (this.remoteShutdownSwich === 0) {
      return '关闭';
    } else if (this.remoteShutdownSwich === 1) {
      return '开启';
    }
    return '-';
  }

  get h5RemoteActivaSwichDesc () {
    if (this.remoteActivaSwich === 0) {
      return '关闭';
    } else if (this.remoteActivaSwich === 1) {
      return '开启';
    }
    return '-';
  }

  /**
   * 初始化
   * @param category 1：端口功率设置 2：刷卡金额设置3：运营参数设置
   * @param resData 网络数据
   */
  initDataWithCategory (category, resData) {
    super.initData(resData);
    if (this.manufacturers) {
      // 在设备列表页面中使用
      if (category === 1) {
        this[portManuFacturesDic[this.manufacturers]] = resData.portMaxPower || 0;
      } else if (category === 2) {
        resData.swipeCardCostNumber = (resData.swipeCardCostNumber || resData.swipeCardCostNumber === 0) ? resData.swipeCardCostNumber - 0 : null;
        this[cardManuFacturesDic[this.manufacturers]] = resData.swipeCardCostNumber || 0;
      }
    } else {
      // 在模板中使用
    }
    if (category === 3) {
      this.fictitiousPower = (this.fictitiousPower || this.fictitiousPower === 0) ? this.fictitiousPower - 0 : null;
    }
    return this;
  }

  /**
   * 数据具体校验-运营参数(category=1)
   * 1: 刷卡扣充电币设置
   * 2: 运营参数设置
   * category 1：端口功率设置 2：刷卡金额设置3：运营参数设置
   * @returns {*}
   */
  checkValidPowerSubmit () {
    // console.log(this);
    if (this.manufacturers) {
      // 专项设置
      return BaseModel.getValidRes(true, '', {
        deviceId: this.deviceId,
        portMaxPower: this[portManuFacturesDic[this.manufacturers]]
      });
    }
    // 模板中设置
    if (this.portPowerSY && this.portPowerYD && this.portPowerCX) {
      return BaseModel.getValidRes(true, '', {
        category: 1,
        portPowerConfig: {
          portPowerCX: this.portPowerCX,
          portPowerYD: this.portPowerYD,
          portPowerSY: this.portPowerSY
        }
      });
    }
    return BaseModel.getValidRes(false, '请将所有功率设置完成');
  }

  /**
   * 数据具体校验-运营参数(category=2)
   * 1: 刷卡扣充电币设置
   * 2: 运营参数设置
   * category 1：端口功率设置 2：刷卡金额设置3：运营参数设置
   * @returns {*}
   */
  checkValidParameterSubmit () {
    if (this.manufacturers) {
      // 专项设置
      const swipeCardCostNumber = this[cardManuFacturesDic[this.manufacturers]];
      // 刷卡扣充电币校验
      if (swipeCardCostNumber < 1 || !isNumber(swipeCardCostNumber)) {
        return BaseModel.getValidRes(false, '刷卡扣充电币只可输入正整数，最小仅为1。', {});
      }
      // 刷卡扣充电币数量入参
      return BaseModel.getValidRes(true, '', {
        deviceId: this.deviceId,
        swipeCardCostNumber: swipeCardCostNumber
      });
    }
    // 刷卡
    return BaseModel.getValidRes(true, '', {
      category: 2,
      swipeCardConfig: {
        swipeCardCX: this.swipeCardCX,
        swipeCardYD: this.swipeCardYD,
        swipeCardSY: this.swipeCardSY
      }
    });
  }

  /**
   * 数据具体校验-运营参数设置校验(category=3)
   * @returns {*}
   */
  checkValidOperateParameterSubmit () {
    if (this.fictitiousPower < 0 || !isNumber(this.fictitiousPower)) {
      return BaseModel.getValidRes(false, '结算功率虚位设置,只可输入正整数或0', {});
    }
    if (this.maxChargePercentSwitch === 1) {
      if (this.maxChargePercent < 0 || !isFloat(this.maxChargePercent)) {
        return BaseModel.getValidRes(false, '最大充电比例,只可输入大于0的数字，仅支持小数点后两位', {});
      }
    }
    if (this.powerLossPercentSwitch === 1) {
      if (this.powerLossPercent < 0 || !isFloat(this.powerLossPercent)) {
        return BaseModel.getValidRes(false, '线损比例,只可输入大于0的数字，仅支持小数点后两位', {});
      }
    }
    if (this.orderMaxPowerSwitch === 1) {
      if (this.orderMaxPower < 1 || !isNumber(this.orderMaxPower)) {
        return BaseModel.getValidRes(false, '时长模式的限制功率, 仅支持大于0的正整数', {});
      }
    }
    if (this.minUseKwh <= 0 || !isFloat(this.minUseKwh)) {
      return BaseModel.getValidRes(false, '最低消费电量,只可输入大于0的数字，仅支持小数点后两位', {});
    }

    const data = {
      operationType: this.operationType,
      freeConfig: this.freeConfig,
      fictitiousPower: this.fictitiousPower,
      maxChargePercentSwitch: this.maxChargePercentSwitch,
      maxChargePercent: this.maxChargePercent,
      powerLossPercentSwitch: this.powerLossPercentSwitch,
      powerLossPercent: this.powerLossPercent,
      minUseKwh: this.minUseKwh,
      orderMaxPowerSwitch: this.orderMaxPowerSwitch,
      orderMaxPower: this.orderMaxPower,
      remoteShutdownSwich: this.remoteShutdownSwich,
      remoteActivaSwich: this.remoteActivaSwich
    };

    if (this.manufacturers) {
      data.deviceId = this.deviceId;
      // 结算功率入参
      return BaseModel.getValidRes(true, '', data);
    }
    data.deviceStatus = this.deviceStatus;
    return BaseModel.getValidRes(true, '', {
      category: 3,
      operationConfig: data
    });
  }
}
