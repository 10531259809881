
export default {
  data () {
    return {
      tableColumns: [
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('name', '模版名称', 140).setSpan({}),
        BaseTableColumn.initData('categoryDesc', '模板类型', 80).setSpan({}),
        BaseTableColumn.initData('createTime', '下发日期', 140).setSpan({}).setSortType('timeAsc'),
        BaseTableColumn.initData('communityNum', '小区总数').setWidth(100).setSpan({}),
        BaseTableColumn.initData('deviceNum', '充电桩总数').setWidth(100).setSpan({}),
        BaseTableColumn.initData('createUser', '下发操作人', 160),
        BaseTableColumn.initData('status', '下发状态', 80).setStatus([
          { value: 0, type: 1 },
          { value: 1, type: 4 },
          { value: 2, type: 2 }
        ], 'statusDesc'),
        BaseTableColumn.initData('', '下发充电桩结果').setWidth(100).setKeyValueList([
          { label: '成功:', labelWidth: 50, key: 'h5SuccessAmountStr', attr: { className: 'text-color-finish' } },
          { label: '失败:', labelWidth: 50, key: 'h5ErrorAmountStr', attr: { className: 'text-color-error' } }
        ]),
        BaseTableColumn.initData('', '操作').setWidth(140).setAlign('center').setButtons([
          { title: '导出明细', key: 'export', permission: 'system:admin:template:exportComputeFee' },
          { title: '查看模版', key: 'check', permission: 'system:admin:template:getComputeFee' }
        ], ({ key, title }, row, index) => {
          if (key === 'check') {
            this.requestDetail(row.templateId, 'templateModel');
          } else {
            if (row.status !== 2) {
              return this.$Message.warning(`设备${row.status === 1 ? '正在' : '准备'}下发，处理完成状态才能导出明细`);
            }
            this.requestDownload(row.templateConfigRecordId + '');
          }
        })
      ]
    };
  }
};
