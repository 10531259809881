import { DeviceTypeVersionModel } from '@/pages/030__basicSetting/deviceTypeList/model/DeviceTypeVersionDataModel';

export default {
  data () {
    return {
      tableColumns: [
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('version', '版本号').setWidth(100).setSpan({ canCopy: true }),
        BaseTableColumn.initData('instructions', '升级指令内容').setSpan({ canCopy: true }),
        BaseTableColumn.initData('h5ShowPackageUrl', '升级包').setAlign('left').setRender((h, { row, index }) => {
          return h('yg-attribute-span', {
            attrs: {
              text: row.h5ShowPackageUrl,
              canTooltip: true,
              tooltipText: row.packageUrl
            }
          });
        }),
        BaseTableColumn.initData('describe', '版本描述').setAlign('left').setSpan({ canTooltip: true }),
        BaseTableColumn.initData('updateTime', '末次更新日期').setWidth(140).setSpan({}),
        BaseTableColumn.initData('updateBy', '操作人', 80).setSpan({}),
        BaseTableColumn.initData('setting', '操作').setWidth(80).setAlign('center').setButtons([
          { title: '编辑', name: 'edit', permission: 'system:device:updateDeviceVersion' },
          { title: '删除', name: 'del', permission: 'system:device:deleteDeviceVersion' }
        ], ({ name }, row, index) => {
          switch (name) {
          case 'edit':
            this.dialog.submitModel = DeviceTypeVersionModel.deepCopy(row);
            break;
          case 'del':
            this.$Modal.confirm({
              title: '操作提示',
              content: '是否确认操作？',
              onOk: () => {
                console.log(row, row.id);
                this.requestDel(row.id);
              }
            });
            break;
          }
        })
      ]
    };
  }
};
