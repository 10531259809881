<!--
    Created by 王丽莎 on 2024/08/29.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：运营管理-充电桩分账模版-模版下发记录
-->
<style lang="less">
#separate-accounts-template-record {
  .text-color-error {
    color: @errorColor;
    font-weight: 600;
  }
}
</style>

<template>
  <yg-drawer
    :show-bottom="false"
    width="90%"
    :back-type="1"
    title="模版下发记录"
    @on-close="_close"
  >
    <div
      id="separate-accounts-template-record"
      slot="content"
    >
      <div v-permission="'system:admin:template:issueAllocateRecord'">
        <yg-search-area
          :show-info="false"
          :value="searchOption"
          @on-search="_clickSearch"
        />
      </div>
      <div class="setting-area">
        <yg-setting-area
          :items="settingItems"
          :total="page.total"
          @on-click="_clickSetting"
        />
      </div>
      <div
        v-permission="'system:admin:template:issueAllocateRecord'"
        class="padding-primary-top"
      >
        <yg-table
          :columns="tableColumns"
          :data="tableData"
          :page="page"
          @on-page-change="onChangePage"
        />
      </div>
      <v-template
        v-if="dialog.templateModel"
        :model="dialog.templateModel"
        @on-close="dialog.templateModel = null"
      />
    </div>
  </yg-drawer>
</template>

<script>
import { SeparateAccountsDetailModel, SeparateAccountsRecordListModel } from '../model/SeparateAccountsDataModel';
import ColumnMixin from '../mixin/recordTableColumn';
import OperationsApiSet from '@/request/api/OperationsApiSet';
import vTemplate from '../view/template.vue';
import DownloadApiSet from '@/request/api/DownloadApiSet';

export default {
  components: { vTemplate },
  mixins: [ColumnMixin],
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      searchOption: {
        templateName: BaseSearchModel.initData('模版名称', 'templateName', 'Input'),
        category: BaseSearchModel.initData('分账方式', 'category', 'Select').setData([
          { label: '按比例分账', value: 1 },
          { label: '按电费服务费分账', value: 2 }
        ])
      },
      settingItems: [
        BaseSettingModel.initData('刷新列表', 'refresh', 'md-refresh').setPermission('system:admin:template:issueAllocateRecord')
      ],
      page: { current: 1, total: 0 },
      tableData: [],
      tableSort: null,
      dialog: {
        templateModel: null
      }
    };
  },
  created () {},
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    onChangePage (page, sortDic) {
      this.page.current = page;
      if (sortDic) {
        this.tableSort = sortDic;
      }
      this.requestData();
    },
    _clickSetting (key) {
      console.log(key);
      if (key === 'refresh') {
        this.page.current = 1;
        this.requestData();
      }
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    _clickSearch (option) {
      this.searchOption = option;
      this.page.current = 1;
      this.requestData();
    },
    _close () {
      this.$emit('on-close');
    },
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    // 请求列表
    requestData () {
      this.tableData = [];
      this.page.total = 0;
      const api = OperationsApiSet.issueAllocateRecord;
      const s = this.searchOption;
      api.params = {
        timeAsc: '', // 排序在下面赋值，判断tableSort变量
        templateName: s.templateName.value,
        category: s.category.value,
        pageSize: this.$config.pageSize,
        pageNo: this.page.current
      };
      if (this.tableSort) {
        api.params[this.tableSort.sortReqKey] = this.tableSort.sortReqValue;
      }
      console.log(api.params);
      this.$http(api).then(res => {
        const resData = res.data;
        this.page.total = resData.total;
        this.tableData = SeparateAccountsRecordListModel.initListWithDataList(resData.rows);
        // const resData = SeparateAccountsRecordListModel.createFakeDataList();
        // this.tableData = SeparateAccountsRecordListModel.initListWithDataList(resData);
      });
    },
    // 请求详情
    requestDetail (templateId, key) {
      const api = OperationsApiSet.qryAllocateTemplateDetail;
      api.params = {
        templateId
      };
      this.$http(api).then(res => {
        const resData = res.data;
        this.dialog[key] = SeparateAccountsDetailModel.initModelWithData(resData);
        // const resData = SeparateAccountsDetailModel.createFakeData();
        // this.dialog[key] = SeparateAccountsDetailModel.initModelWithData(resData);
      });
    },
    // 请求导出
    requestDownload (id) {
      if (this.tableData.length === 0) {
        return this.$Message.warning({
          title: '无数据可导出',
          content: '请先查询到结果后再进行导出',
          okText: '我知道了'
        });
      }
      const api = DownloadApiSet.downloadCreate;
      api.params = {
        reportType: 31,
        reportCondition: {
          templateConfigRecordId: id
        }
      };
      this.$http(api).then(res => {
        this.$Modal.confirm({
          title: '操作成功',
          content: '请至下载中心导出',
          okText: '立即前往',
          cancelText: '稍后前往',
          onOk: () => {
            this.$router.push({
              name: 'download_list'
            });
          }
        });
      });
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
