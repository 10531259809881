<!--
    Created by 程雨喵'mac on 2024/11/28.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    页面名称：设备经营报表
-->
<style lang="less">
#data-analysis {
  height: 100%;
  .split-box {
    min-width: 100%;
    overflow-x: auto;
    height: 100%;
  }
  .card-outer {
    margin: 0 -15px;
    .total-data-box {
      display: flex;
      overflow: auto;
      //flex-wrap: wrap;
      //overflow: hidden;
      border-radius: @borderRadiusMin;
      border: 1px solid @lineColorNormal;
      border-right: 0;
      .total-data-item {
        flex: 1;
        min-width: 100px;
        border-right: 1px solid @lineColorNormal;
        &:nth-of-type(2n) {
          .total-data-label {
            //background-color: transparent;
          }
        }
        .total-data-label {
          padding: 8px 5px;
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          white-space: pre-line;
          color: @textColorLight;
          background-color: @backColorNormal;
        }
        .total-data-value {
          .display-flex();
          line-height: 55px;
          font-weight: 600;
          font-size: 13px;
        }
        .sa-price-total-data-value {
          height: 55px;
          padding: 7px 0;
          box-sizing: border-box;
          .__g-table-key-value {
            line-height: 14px;
          }
          .__g-table-key-value + .__g-table-key-value {
            margin-top: 0;
          }
        }
      }
    }
  }
  .color-text-strong { color: @adornColor; font-weight: 600 }
  .color-text-finish { color: @finishColor; }
  .color-text-primary { color: @textColorNormal; }
  td.ivu-table-expanded-cell {
    padding: 20px;
  }
}
</style>

<template>
  <div id="data-analysis">
    <yg-split-vertical-box
      v-model="isOpen"
    >
      <div
        slot="left"
        class="split-box"
      >
        <!-- 查询条件 -->
        <div v-permission="'system:statis:device:list'">
          <yg-search-area
            ref="ygSearchArea"
            :show-info="false"
            :value="searchOption"
            :is-stealth="true"
            :show-rest="false"
            @on-search="_clickSearch"
            @on-change-stealth="tableResizeFlag = !tableResizeFlag"
          />
        </div>
        <yg-simple-table
          v-permission="'system:statis:device:list'"
          :columns="totalDataColumns"
          :data="[pageData]"
          :top-title="currentSearchTime + ' 统计概览'"
          :show-control="false"
          :show-pager="false"
          storage-column-key="dataStatistics_total_deviceAnalysis"
        />
        <div style="height: 15px" />
        <yg-simple-table
          v-permission="'system:statis:device:list'"
          :control-resize="tableResizeFlag"
          :top-columns="tableTopColumns"
          :columns="tableColumns"
          :export-condition="settingExportCondition"
          :export-setting="settingExportItem"
          :data="tableData"
          :page="page"
          storage-column-key="dataStatistics_deviceAnalysis"
          @on-page-change="onChangePage"
        />
      </div>
      <div
        slot="right"
        class="right-box"
      >
        <v-charts-analysis
          v-if="isOpen"
        />
      </div>
    </yg-split-vertical-box>
  </div>
</template>

<script>
import vChartsAnalysis from '@/pages/106__dataStatistics/_components/charts/index.vue';
import { DeviceAnalysisListModel, DeviceAnalysisTotalModel } from './model/DeviceAnalysisDataModel';
import DataStatisticsApiSet from '@/request/api/DataStatisticsApiSet';
import ColumnMixin from './mixin/tableColumn';
import { getLastDate } from '@/libs/utils';

export default {
  components: { vChartsAnalysis },
  mixins: [ColumnMixin],
  data () {
    return {
      totalDataColumns: [
        BaseTableColumn.initData('portUseRate', '端口使用率').setSpan({ canTextUnit: 'p' }),
        BaseTableColumn.initData('enabledDeviceNum', '充电桩总数').setSpan({}).setH5HeaderColumnTooltip('统计日期范围内所有设备去重后的数量'),
        BaseTableColumn.initData('enabledPortNum', '端口总数').setSpan({}).setH5HeaderColumnTooltip('统计日期范围内每天可用端口累加'),
        BaseTableColumn.initData('availableOrderNum', '有效充电订单总单数').setSpan({}),
        BaseTableColumn.initData('h5AvailableOrderAmount', '有效充电订单总消费金额').setSpan({}),
        BaseTableColumn.initData('customerAvgAmount', '客单价').setSpan({}),
        BaseTableColumn.initData('manualRefundOrderNum', '人工退款单总单数').setSpan({}),
        BaseTableColumn.initData('manualRefundOrderAmount', '人工退款单总金额').setSpan({})
      ],
      searchOption: {
        time: BaseSearchModel.initData('统计日期范围', 'time', 'DatePickerRange').setDefault([getLastDate(1), getLastDate(1)]).setClearable(false),
        deviceNoLabel: BaseSearchModel.initData('设备型号名称', 'deviceNoLabel', 'Input').setLabelSelect([
          { value: 'deviceNumber', label: '设备编码' },
          { value: 'deviceName', label: '设备名称' }
          // { value: 'deviceModelName', label: '设备型号名称' },
          // { value: 'imei', label: 'IMEI号' }
        ]),
        chargingPileType: BaseSearchModel.initData('端口类别', 'chargingPileType', 'YgGlobalSelect').setData('chargingPileType'),
        status: BaseSearchModel.initData('启用状态', 'status', 'Select').setData([
          { value: 1, label: '已启用' },
          { value: 0, label: '已停用' }
        ]),
        canopyStatus: BaseSearchModel.initData('雨棚状态', 'canopyStatus', 'Select').setData([
          { label: '未装', value: 1 },
          { label: '已装', value: 2 }
        ]),
        deviceProviderIdList: BaseSearchModel.initData('设备商名称', 'deviceProviderIdList', 'YgGlobalSelect').setMultiple(true).setData('deviceProvider').setDefault([]),
        isOnline: BaseSearchModel.initData('在线状态', 'isOnline', 'Select').setData([
          { label: '离线', value: '0' },
          { label: '在线', value: '1' }
        ]),
        projectNoLabel: BaseSearchModel.initData('小区名称', 'projectNoLabel', 'Input').setLabelSelect([
          { value: 'communityName', label: '小区名称' },
          { value: 'pcSapCode', label: '物业SAP项目编码' },
          { value: 'settleCode', label: '结算中心项目编码' }
        ]),
        regionCompany: BaseSearchModel.initData('物业区域公司', 'regionCompany', 'YgGlobalSelect').setData('regionalCompanyList').setExclusions(['officeCode', 'region']),
        officeCode: BaseSearchModel.initData('吾行办事处', 'officeCode', 'YgGlobalSelect').setData('officeList').setExclusions(['regionCompany', 'region']),
        region: BaseSearchModel.initData('省/市/区', 'region', 'YgRegionCascader').setData(2).setExclusions(['regionCompany', 'officeCode']),
        // sitePosition: BaseSearchModel.initData('点位位置', 'sitePosition', 'Select').setData([
        //   { label: '地面', value: 1 },
        //   { label: '地下', value: 2 },
        //   { label: '架空层', value: 3 }
        // ]).setShowInMore(true),
        operationType: BaseSearchModel.initData('运营类型', 'operationType', 'Select').setMultiple(true).setData([
          { label: '自有', value: '0' },
          { label: '共建', value: '1' },
          { label: '外拓', value: '2' }
        ])
      },
      settingExportCondition: {},
      settingExportItem: BaseSettingModel.initData('导出列表', 'download', 'md-cloud-download').setExport().setExportReportType(26).setPermission('system:statis:device:export'),
      isOpen: false,
      page: { current: 1, total: 0 },
      tableData: [],
      // table重新渲染
      tableResizeFlag: false,
      pageData: {},
      // 当前统计时间
      currentSearchTime: ''
    };
  },
  created () {},
  mounted () {
    console.log(this.$el.clientWidth);
  },
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    getRequestParams () {
      const s = this.searchOption;
      const searchStartTime = new Date(s.time.value[0]).getTime();
      const earliestTime = new Date('2024-04-01 00:00:00').getTime();
      const searchEndTime = new Date(s.time.value[1]).getTime();
      const deadlineDate = getLastDate(1);
      const deadlineTime = new Date(deadlineDate + ' 23:59:59').getTime();
      // 最早时间为24年4月1日，最晚时间为昨日
      if (searchStartTime < earliestTime) {
        this.$refs.ygSearchArea.isStealthShowSearch = true;
        return {
          status: false,
          msg: '开始日期最早为2024年04月01日',
          params: {}
        };
      }
      if (searchEndTime > deadlineTime) {
        this.$refs.ygSearchArea.isStealthShowSearch = true;
        return {
          status: false,
          msg: '结束日期最晚为' + getLastDate(1),
          params: {}
        };
      }
      return {
        status: true,
        msg: '',
        params: {
          chargingPileType: s.chargingPileType.value,
          // deviceSource: s.deviceSource.value,
          status: s.status.value,
          deviceProviderIdList: s.deviceProviderIdList.value,
          isOnline: s.isOnline.value,
          officeCode: s.officeCode.value,
          orgCode: s.regionCompany.value,
          // sitePosition: s.sitePosition.value,
          canopyStatus: s.canopyStatus.value,
          operateTypeList: s.operationType.value || [],
          provinceCode: s.region.value[0] || '',
          cityCode: s.region.value[1] || '',
          districtCode: s.region.value[2] || '',
          statisDateBegin: s.time.value[0] || '',
          statisDateEnd: s.time.value[1] || ''
        }
      };
    },
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    _clickSearch (option) {
      this.searchOption = option;
      this.page.current = 1;
      this.requestData();
    },
    _clickSetting (key) {},
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    requestData () {
      const paramsData = this.getRequestParams();
      if (!paramsData.status) {
        return this.$Message.warning(paramsData.msg);
      }
      const s = this.searchOption;
      const sTime = s.time.value[0].split(' ')[0].replaceAll('-', '.');
      const eTime = s.time.value[1].split(' ')[0].replaceAll('-', '.');

      this.currentSearchTime = sTime + '~' + eTime;

      this.tableData = [];
      this.page.total = 0;
      const api = DataStatisticsApiSet.getStatisticsList;
      api.params = paramsData.params;
      api.params.pageNo = this.page.current;
      api.params.pageSize = 200;
      api.params[s.deviceNoLabel.labelSelectValue] = s.deviceNoLabel.value;
      api.params[s.projectNoLabel.labelSelectValue] = s.projectNoLabel.value;

      this.settingExportCondition = api.params;

      this.$http(api).then(res => {
        console.log(res);
        const resData = res.data;
        this.page.total = resData.total;
        this.pageData = DeviceAnalysisTotalModel.initModelWithData(resData.statisSummary || {});
        this.tableData = DeviceAnalysisListModel.initListWithDataList(resData.list);
        console.log(this.pageData);
      });
      // setTimeout(() => {
      //   const resList = DeviceAnalysisListModel.createFakeDataList(200);
      //   this.page.total = resList.length * 2;
      //   this.tableData = DeviceAnalysisListModel.initListWithDataList(resList);
      // }, 300);
      // const resData = DataAnalysisTotalModel.createFakeData();
      // this.pageData = DataAnalysisTotalModel.initModelWithData(resData);
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
