<!--
    Created by 程雨喵'mac on 2024/9/2.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：分账模板详情
-->
<style lang="less">
</style>

<template>
  <div class="bill-template-detail">
    <Form>
      <FormItem
        v-for="(item, i) in inputItems"
        :key="i"
        :label="item.label + ':'"
        :label-width="item.labelWidth"
        :required="item.isRequired"
      >
        <RadioGroup
          v-if="item.tagName === 'Radio'"
          v-model="model[item.key]"
          @on-change="checkSubmit"
        >
          <Radio
            v-for="(sub, r) in item.data"
            :key="'r-' + r"
            :disabled="!isEdit"
            :label="sub.value"
          >
            <span>{{ sub.label }}</span>
          </Radio>
        </RadioGroup>
        <Input
          v-else
          v-model.trim="model[item.key]"
          :placeholder="item.placeholder"
          :disabled="!isEdit"
          class="display-flex-1"
          @input="checkSubmit"
        />
      </FormItem>
    </Form>
    <v-bill-ratio
      v-if="model.category === 1"
      ref="billRatio"
      :is-edit="isEdit"
      :model="model"
    />
    <div
      v-else
      class="display-flex display-flex-v-center display-flex-h-center"
      style="height: 180px"
    >
      敬请期待^^
    </div>
  </div>
</template>

<script>
import vBillRatio from '@/pages/050__deviceManager/chargingPileList/view/setting/billSetting/billRatio.vue';
export default {
  components: { vBillRatio },
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      inputItems: [
        { label: '分账模板名称', labelWidth: 120, placeholder: '请输入分账模版名称', key: 'name', isRequired: true },
        {
          label: '分账方式',
          tagName: 'Radio',
          labelWidth: 120,
          placeholder: '',
          key: 'category',
          isRequired: true,
          data: [
            { value: 1, label: '按比例分账' },
            { value: 2, label: '电费服务费分账' }
          ]
        }
      ],
      orgDataList: []
    };
  },
  created () {
    if (this.isEdit) {
      this.checkSubmit();
    }
  },
  methods: {
    checkSubmit () {
      const item = this.inputItems.find(v => !this.model[v.key]);
      this.$emit('on-change', {
        disabled: !!item,
        msg: item ? (item.label + '不可为空') : ''
      });
    },
    // 外部调用
    $checkSubmitDataValid () {
      const ele = this.$refs.billRatio;
      // 按功率计费
      if (this.model.category === 1) {
        if (ele.$checkSubmit()) {
          return this.model.checkValidAddSubmit();
        }
        return BaseModel.getValidRes(false);
      }
      return BaseModel.getValidRes(false, '分账方式暂不支持');
    }
  }
};
</script>
