/**
 * @date:2024/12/25
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 * 软件版本相关接口
 */

import BaseApi from '@/request/base/BaseApi';

class SoftVersionApiSet {
  constructor () {
    this.getDeviceVersions = BaseApi.createModel('查询软件版本', '/opmp/device/getDeviceVersions', true).setMethod(1);
    this.addDeviceVersion = BaseApi.createModel('新增软件版本', '/opmp/device/addDeviceVersion', true).setMethod(1);
    this.updateDeviceVersion = BaseApi.createModel('编辑软件版本', '/opmp/device/updateDeviceVersion', true).setMethod(1);
    this.deleteDeviceVersion = BaseApi.createModel('删除软件版本', '/opmp/device/deleteDeviceVersion', true).setMethod(1);
    this.getDeviceAvailableVersion = BaseApi.createModel('获取设备可用版本', '/opmp/device/getDeviceAvailableVersion', true).setMethod(1);
    this.adjustDeviceVersion = BaseApi.createModel('调整设备软件版本', '/opmp/device/adjustDeviceVersion', true).setMethod(1);
    this.getDeviceAdjustRecord = BaseApi.createModel('获取设备版本调整日志', '/opmp/device/getDeviceAdjustRecord', true).setMethod(1);
  }
}

export default new SoftVersionApiSet();
