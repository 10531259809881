<!--
    Created by 王丽莎 on 2023/12/12.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：交易管理-充电退款订单列表
-->
<style lang="less">
#charging-refund-order {
  .col-text-strong {
    color: @adornColor;
    font-weight: 600;
  }
  .col-text-finish {
    color: @finishColor;
  }
}
</style>

<template>
  <div id="charging-refund-order">
    <!-- 查询条件 -->
    <div
      v-permission="'system:chargeOrder:refund:list'"
    >
      <yg-search-area
        :show-info="false"
        :value="searchOption"
        @on-search="_clickSearch"
      />
    </div>
    <div>
      <yg-setting-area
        :items="settingItems"
        :total="page.total"
        :export-amount="settingExportIdList.length"
        :export-select-id-list="settingExportIdList"
        :export-condition="settingExportCondition"
        @on-click="_clickSetting"
      />
    </div>
    <div
      v-permission="'system:chargeOrder:refund:list'"
      class="padding-primary-top"
    >
      <yg-table
        :columns="tableColumns"
        :data="tableData"
        :page="page"
        :row-class-name="mixinTableRowClassName"
        @on-row-expand="mixinTableOnRowExpand"
        @on-page-change="onChangePage"
        @on-selection="onChangeSelection"
      />
    </div>
    <!--弹窗：查看各类型订单-->
    <yg-order-list
      v-if="!!dialog.orderListModel"
      :parameter-type="dialog.parameterType"
      :type="dialog.orderListType"
      :model="dialog.orderListModel"
      @on-close="dialog.orderListModel = null"
      @on-sure="dialog.orderListModel = null"
    />
  </div>
</template>

<script>
import { ChargingRefundOrderDataModel } from './model/ChargingRefundOrderDataModel';
import ColumnMixin from '@/pages/070__dealManager/chargingRefundOrder/mixin/tableColumn';
import pageTableExpandMixin from '@/libs/mixins/pageTableExpand';
import OrderApiSet from '@/request/api/OrderApiSet';
import commonPageDrawerMixin from '@/components/common/order-list/commonPageDrawerMixin';
import { getLastDate, getNowDate } from '@/libs/utils';

export default {
  name: 'ChargingRefundOrder',
  components: {},
  mixins: [ColumnMixin, pageTableExpandMixin, commonPageDrawerMixin],
  props: {},
  data () {
    return {
      searchOption: {
        deviceNoLabel: BaseSearchModel.initData('设备编码', 'deviceNoLabel', 'Input').setLabelSelect([
          { value: '1', label: '设备编码' },
          { value: '2', label: '设备名称' },
          { value: '3', label: '设备型号名称' },
          { value: '4', label: 'IMEI号' }
        ]),
        refundStatus: BaseSearchModel.initData('退款状态', 'refundStatus', 'Select').setData([
          { label: '待退款 ', value: '0' },
          { label: '退款成功', value: '1' },
          { label: '支付关闭', value: '2' }
        ]),
        payType: BaseSearchModel.initData('退款渠道', 'payType', 'Select').setData([
          { label: '微信支付 ', value: '1' },
          { label: '充电币账户', value: '2' }
        ]),
        // sourceType: BaseSearchModel.initData('订单来源', 'sourceType', 'YgGlobalSelect').setData('orderDataSourceType'),
        refundType: BaseSearchModel.initData('退款业务类型', 'refundType', 'Select').setData([
          { label: '自动退款 ', value: '1' },
          { label: '人工退款', value: '2' }
        ]),
        selectOrderLabel: BaseSearchModel.initData('退款订单编码退款订单', 'selectOrderLabel', 'Input').setLabelSelect([
          { value: 'refundOrderNo', label: '退款订单编码' },
          { value: 'bizOrder', label: '充电订单编码' },
          { value: 'tel', label: '用户手机号' },
          { value: 'chargeNumber', label: '充电卡号' },
          { value: 'gwTradeNo', label: '交易渠道订单号' }
        ]),
        communityName: BaseSearchModel.initData('小区名称', 'communityName', 'Input'),
        region: BaseSearchModel.initData('省/市/区', 'region', 'YgRegionCascader').setData(2),
        refundTime: BaseSearchModel.initData('退款申请时间', 'refundTime', 'DatePickerRange').setDefault([getLastDate(30), getNowDate()]).setDatePickerElevator([])
      },
      settingExportIdList: [],
      settingExportCondition: {},
      settingItems: [
        BaseSettingModel.initData('导出列表', 'export', 'md-cloud-download').setExport().setExportReportType(8).setPermission('system:chargeOrder:refund:export')
      ],
      page: { current: 1, total: 0 },
      tableData: [],
      dialog: {
        orderListModel: null,
        orderListType: null,
        parameterType: null
      }
    };
  },
  created () {
    // 配置展开项内容
    this.mixinTableExpandSetColumnOperation(2, [
      {
        title: '设备信息',
        col: '50',
        items: [
          { col: '25', label: '设备编码:', key: 'deviceNumber', canCopy: false, canTooltip: true, canHidePhone: false, canClick: true },
          { col: '25', label: 'IMEI号:', key: 'imei', canCopy: false, canTooltip: true, canHidePhone: false },
          { col: '25', label: '设备名称:', key: 'deviceName', canCopy: false, canTooltip: true, canHidePhone: false },
          { col: '25', label: '设备型号名称:', key: 'deviceModelName', canCopy: false, canTooltip: true, canHidePhone: false }
        ]
      },
      {
        title: '点位信息',
        col: '50',
        items: [
          { col: '25', label: '所属地区:', key: 'h5AddressName', canCopy: false, canTooltip: true, canHidePhone: false },
          { col: '25', label: '小区名称:', key: 'blockName', canCopy: false, canTooltip: true, canHidePhone: false },
          { col: '25', label: '点位名称:', key: 'siteName', canCopy: false, canTooltip: true, canHidePhone: false },
          { col: '25', label: '', key: '', canCopy: false, canTooltip: true, canHidePhone: false }
        ]
      }
    ], (row, index, { key }) => {
      if (key === 'deviceNumber') {
        this.dialog.parameterType = 3;
        this.dialog.orderListType = 5;
        this.dialog.orderListModel = row;
      }
    });
  },
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    onChangeSelection (list) {
      // 1.先把本页的数据清掉
      let selectList = this.settingExportIdList.filter(v => this.tableData.findIndex(v1 => v1._checkId === v._checkId) === -1);
      if (list.length > 0) {
        // 本页有勾选项放进去
        selectList = [...selectList, ...list];
      }
      this.settingExportIdList = selectList;
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    _clickSearch (option) {
      this.searchOption = option;
      this.page.current = 1;
      this.requestData();
    },
    _clickSetting (key) {
      switch (key) {
      case 'download':
        this.$Message.info('点击导出');
        break;
      }
    },
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    requestData () {
      this.tableData = [];
      this.page.total = 0;
      const s = this.searchOption;
      const api = OrderApiSet.getRefundList;
      console.log(s.refundTime.value);
      api.params = {
        queryType: s.deviceNoLabel.value ? s.deviceNoLabel.labelSelectValue : '',
        queryName: s.deviceNoLabel.value,
        // sourceType: s.sourceType.value,
        refundStatus: s.refundStatus.value,
        payType: s.payType.value,
        province: s.region.value[0] || '',
        city: s.region.value[1] || '',
        district: s.region.value[2] || '',
        communityName: s.communityName.value,
        refundTimeStart: s.refundTime.value[0],
        refundTimeEnd: s.refundTime.value[1],
        refundType: s.refundType.value,
        pageSize: this.$config.pageSize,
        pageNo: this.page.current
      };
      // 筛选中可下拉的label
      // api.params[s.deviceNoLabel.labelSelectValue] = s.deviceNoLabel.value;
      api.params[s.selectOrderLabel.labelSelectValue] = s.selectOrderLabel.value;
      this.settingExportCondition = api.params;
      console.log(api);
      this.$http(api).then(res => {
        const resData = res.data;
        this.page.total = resData.total;
        const list = ChargingRefundOrderDataModel.initListWithDataList(resData.rows);
        this.tableData = this.mixinTableExpandDataSetExpand(list);
        ChargingRefundOrderDataModel.checkModelIsSelect(this.tableData, this.settingExportIdList);
      });
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
