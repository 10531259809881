<!--
    Created by 程雨喵'mac on 2024/10/22.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：运营参数设置
-->
<style lang="less">
.dc-operate-parameter-submit {
  .submit-table {
    position: relative;
    border-top: 1px solid @lineColorNormal;
    border-radius: @borderRadiusMin;
    &+.submit-table {
      margin-top: @containerGap;
    }
    .submit-table-row {
      display: flex;
      align-items: flex-start;
      border: 1px solid @lineColorNormal;
      border-top: 0;
      height: 40px;
      line-height: @formHeight;
      font-size: 12px;
      .ivu-icon {
        cursor: help;
        color: @textColorLight;
        margin-right: 5px;
      }
      &.is-header {
        background-color: @backColorNormal;
        color: @textColorLight;
        font-weight: 600;
        font-size: 13px;
      }
      .table-col {
        position: relative;
        padding: 5px;
        .display-flex();
        box-sizing: border-box;
        &.style-merge {
          background: @backColorStrong;
          position: absolute;
          width: 100%;
          top: 40px;
          bottom: 0;
          .display-flex();
          border-bottom: 1px solid @lineColorNormal;
          z-index: 1;
        }
        &.is-big-label {
          padding-top: 10px;
          .label-tag {
            position: absolute;
            top: 0;
            left: 0;
            padding: 0 8px;
            font-size: 8px;
            line-height: 12px;
            font-weight: 600;
            color: @themeColor;
            border-radius: 0 0 12px 0;
            background-color: @backColorLight;
            &.tag-electric {
              color: @adornColor;
            }
            &.tag-time {
              color: @finishColor;
            }
          }
        }
        .table-col-inner {
          width: 100%;
          .display-flex();
          white-space: nowrap;
          .ivu-input-number {
            flex: 1;
          }
        }
        &+.table-col {
          border-left: 1px solid @lineColorNormal;
        }
        &.col-t-0 {
          .flex-grow(140px);
          color: @textColorLight;
        }
        &.col-t-1 {
          .flex-grow(160px);
        }
        &.col-t-2 {
          flex: 1;
        }
        &.col-t-3 {
          .flex-grow(100px);
        }
      }
    }
  }
  .input-number-item {
    .display-flex(center, flex-start);
    &.input-multiple-box {
      .flex-grow(100%);
    }
    .input-number-box {
      flex: 1;
      height: @formHeight;
      line-height: @formHeight;
      .input-number {
        margin: 0 5px;
      }
    }
    .tips-text {
      color: @textColorLight;
      font-size: 12px;
      text-decoration: underline;
      cursor: pointer;
    }
    .ivu-poptip-body-content-inner {
      font-size: 12px !important;
      color: @textColorError !important;
    }
  }
}
</style>

<template>
  <div class="dc-operate-parameter-submit">
    <div
      v-for="(item, i) in tableRows"
      :key="i"
      class="submit-table"
    >
      <div class="submit-table-row is-header">
        <div
          v-for="(tableHeadersItem, tableHeadersIndex) in tableHeaders"
          :key="tableHeadersIndex"
          class="table-col"
          :class="'col-t-' + tableHeadersIndex"
        >
          {{ tableHeadersItem }}
        </div>
      </div>
      <div
        v-for="(re, ind) in item.items"
        :key="'ind-' + ind"
        class="submit-table-row"
      >
        <div
          v-if="ind === 0"
          class="table-col col-t-0 style-merge"
        >
          {{ re.submitTypeTitle }}
        </div>
        <div class="table-col col-t-0">
          {{ re.submitTypeTitle }}
        </div>
        <div
          class="table-col col-t-1"
          :class="(re.isPowerMode || re.isElectricMode || re.isTimeMode) && isEdit ? 'is-big-label' : ''"
        >
          {{ re.title }}
          <Icon
            v-if="re.showTip && isEdit"
            type="md-help-circle"
            @click.stop="_clickShowTip(re.key, re.descKey)"
          />
          <!--功率计费模式生效-->
          <span
            v-if="re.isPowerMode && isEdit"
            class="label-tag tag-power"
          >仅功率计费时生效</span>
          <!--电量计费模式生效-->
          <span
            v-else-if="re.isElectricMode && isEdit"
            class="label-tag tag-electric"
          >仅电量计费时生效</span>
          <span
            v-else-if="re.isTimeMode && isEdit"
            class="label-tag tag-time"
          >仅时长计费时生效</span>
        </div>
        <!--编辑时，编辑时，编辑时-->
        <div
          v-if="isEdit"
          class="table-col col-t-2"
        >
          <div class="table-col-inner">
            <InputNumber
              v-if="re.valueTag === 'InputNumber'"
              v-model="model[re.key]"
              :max="re.max"
              :min="re.min"
              :placeholder="re.placeholder"
              :disabled="re.disabled"
              type="number"
              class="display-flex-1"
              @input="checkSubmit"
            />
            <RadioGroup
              v-else-if="re.valueTag === 'Radio'"
              v-model="model[re.key]"
              :value="'1'"
              :disabled="re.disabled"
              @on-change="checkSubmit"
            >
              <Radio
                v-for="(sub, r) in re.data"
                :key="'r-' + r"
                :disabled="re.disabled"
                :label="sub.value"
              >
                {{ sub.label }}
              </Radio>
            </RadioGroup>
            <Select
              v-else-if="re.valueTag === 'Select'"
              v-model="model[re.key]"
              transfer
              :clearable="re.clearable"
              :disabled="re.disabled"
              style="width: 100%"
              @input="checkSubmit"
            >
              <Option
                v-for="(op, p) in re.data"
                :key="p + '-op'"
                :value="op.value"
              >
                {{ op.label }}
              </Option>
            </Select>
            <div
              v-else-if="re.key === 'fictitiousPower'"
              class="input-number-item display-flex-1"
            >
              <div class="input-number-box">
                <span>各档结束功率加</span>
                <InputNumber
                  v-model="model[re.key]"
                  :max="re.max"
                  :min="re.min"
                  class="input-number"
                  @input="checkSubmit"
                />
                <span>瓦</span>
              </div>
            </div>
            <div
              v-else-if="re.valueTag === 'multiple'"
              class="input-number-item input-multiple-box"
            >
              <!--多行-->
              <div
                v-for="(muItem, muIndex) in re.multipleRows"
                :key="'mu-' + muIndex"
                class="input-number-box"
              >
                <RadioGroup
                  v-if="muItem.valueTag === 'Radio'"
                  v-model="model[muItem.key]"
                  :disabled="muItem.disabled"
                  @on-change="checkSubmit"
                >
                  <Radio
                    v-for="(sub1, r1) in muItem.data"
                    :key="'r-1-' + r1"
                    :disabled="muItem.disabled"
                    :label="sub1.value"
                  >
                    {{ sub1.label }}
                  </Radio>
                </RadioGroup>
                <template v-else-if="muItem.valueTag === 'InputNumber'">
                  <InputNumber
                    v-model="model[muItem.key]"
                    :max="muItem.max"
                    :min="muItem.min"
                    :disabled="model[muItem.disabledKey] !== muItem.canUseValue"
                    type="number"
                    class="display-flex-1"
                    @input="checkSubmit"
                  />
                  <span
                    v-if="re.multipleValueUnit"
                    style="margin-left: 5px;"
                  >{{ re.multipleValueUnit }}</span>
                </template>
              </div>
            </div>
            <div
              v-if="re.valueUnit"
              style="margin-left: 5px;"
            >
              {{ re.valueUnit }}
            </div>
          </div>
        </div>
        <!--展示时，展示时，展示时-->
        <div
          v-else
          class="table-col col-t-2"
        >
          <div
            v-if="re.key === 'fictitiousPower'"
            class="table-col-inner"
          >
            各档结束功率加{{ model[re.key] || model[re.key] === 0 ? model[re.key] : '-' }}瓦
          </div>
          <div
            v-else
            class="table-col-inner"
          >
            <span v-if="re.descKey">{{ model[re.descKey] || '-' }}</span>
            <span v-else>{{ model[re.key] || '-' }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      tableHeaders: ['配置类型', '配置项', '相关值'],
      tableRows: [
        {
          title: '运营参数设置',
          submitType: 2,
          buttonDisabled: true,
          buttonDisabledMsg: '',
          items: [
            {
              submitTypeTitle: '运营参数设置',
              title: '6分钟内免费',
              key: 'freeConfig',
              descKey: 'h5FreeConfigDesc',
              valueTag: 'Radio',
              disabled: false,
              isRequired: true,
              data: [
                {
                  label: '开启',
                  value: 1
                },
                {
                  label: '关闭',
                  value: 0
                }
              ]
            },
            {
              title: '设备运营类型',
              key: 'operationType',
              descKey: 'operationTypeDesc',
              valueTag: 'Select',
              isRequired: true,
              data: [
                {
                  label: '自有',
                  value: 0
                },
                {
                  label: '共建',
                  value: 1
                },
                {
                  label: '外拓',
                  value: 2
                }
              ]
            },
            {
              title: '结算功率虚位设置',
              valueTag: 'InputNumberBox',
              key: 'fictitiousPower',
              isRequired: true,
              disabled: false,
              min: 0,
              max: 30,
              isPowerMode: true, // 仅功率计费时生效
              showTip: true
            },
            {
              title: '最大充电比例',
              valueTag: 'multiple',
              descKey: 'h5MaxChargePercentDesc',
              isElectricMode: true, // 仅电量计费时生效
              isRequired: false,
              showTip: true,
              multipleValueUnit: '%',
              multipleRows: [
                {
                  title: '',
                  valueTag: 'Radio',
                  key: 'maxChargePercentSwitch',
                  disabled: false,
                  data: [
                    { label: '关闭', value: 0 },
                    { label: '开启', value: 1 }
                  ]
                },
                {
                  title: '',
                  valueTag: 'InputNumber',
                  key: 'maxChargePercent',
                  disabledKey: 'maxChargePercentSwitch',
                  canUseValue: 1,
                  min: 0,
                  max: 999
                }
              ]
            },
            {
              title: '线损比例',
              valueTag: 'multiple',
              descKey: 'h5PowerLossPercentDesc',
              isElectricMode: true, // 仅电量计费时生效
              isRequired: false,
              showTip: true,
              multipleValueUnit: '%',
              multipleRows: [
                {
                  title: '',
                  valueTag: 'Radio',
                  key: 'powerLossPercentSwitch',
                  disabled: false,
                  showTip: true,
                  data: [
                    { label: '关闭', value: 0 },
                    { label: '开启', value: 1 }
                  ]
                },
                {
                  title: '',
                  valueTag: 'InputNumber',
                  key: 'powerLossPercent',
                  disabledKey: 'powerLossPercentSwitch',
                  canUseValue: 1,
                  showTip: true,
                  min: 0,
                  max: 9999
                }
              ]
            },
            {
              title: '最低消费电量',
              valueTag: 'InputNumber',
              key: 'minUseKwh',
              isElectricMode: true, // 仅电量计费时生效
              isRequired: false,
              disabled: false,
              valueUnit: '度',
              placeholder: '请输入',
              min: 0,
              max: 999
            },
            {
              title: '时长模式的限制功率',
              valueTag: 'multiple',
              descKey: 'h5OrderMaxDesc',
              isTimeMode: true, // 仅时长计费时生效
              isRequired: false,
              showTip: true,
              multipleValueUnit: '瓦',
              multipleRows: [
                {
                  title: '',
                  valueTag: 'Radio',
                  key: 'orderMaxPowerSwitch',
                  disabled: false,
                  showTip: true,
                  data: [
                    { label: '关闭', value: 0 },
                    { label: '开启', value: 1 }
                  ]
                },
                {
                  title: '',
                  valueTag: 'InputNumber',
                  key: 'orderMaxPower',
                  disabledKey: 'orderMaxPowerSwitch',
                  canUseValue: 1,
                  showTip: true,
                  min: 0,
                  max: 9999
                }
              ]
            },
            {
              title: '远程结束充电',
              key: 'remoteShutdownSwich',
              descKey: 'h5RemoteShutdownSwichDesc',
              valueTag: 'Radio',
              disabled: false,
              isRequired: true,
              showTip: true,
              data: [
                {
                  label: '开启',
                  value: 1
                },
                {
                  label: '关闭',
                  value: 0
                }
              ]
            },
            {
              title: '远程开始充电',
              key: 'remoteActivaSwich',
              descKey: 'h5RemoteActivaSwichDesc',
              valueTag: 'Radio',
              disabled: false,
              isRequired: true,
              showTip: true,
              data: [
                {
                  label: '开启',
                  value: 1
                },
                {
                  label: '关闭',
                  value: 0
                }
              ]
            }
          ]
        }
      ]
    };
  },
  created () {
    // 如果有值，需要把别的置为不可用（设备列表点进来会有值，模板点进来没有值）
    if (!this.model.manufacturers) {
      this.tableRows[0].items.splice(1, 0, {
        title: '设备启用状态设置',
        key: 'deviceStatus',
        descKey: 'h5DeviceStatusDesc',
        valueTag: 'Radio',
        disabled: false,
        data: [
          {
            label: '设备全部开启',
            value: 1
          },
          {
            label: '设备全部关闭',
            value: 0
          }
        ]
      });
    }
  },
  methods: {
    checkSubmit () {},
    $checkSubmit () {
      const checkItem = this.tableRows[0].items.find(r => r.isRequired && this.model[r.key] === null);
      if (checkItem) {
        return this.$Message.warning(checkItem.title + '不可为空');
      }
      return { success: true };
    },
    // 结算功率虚位说明
    _clickShowTip (key, descKey) {
      const dic = {
        title: '',
        content: '',
        okText: '我知道了'
      };
      if (key === 'fictitiousPower') {
        dic.title = '结算功率虚位说明';
        dic.content = '同一个充电桩用同一辆车多次充电，因为最高功率临界，造成收费不一致引起客诉。在各收费档的结束功率增加虚位，可减少这种情况。';
      } else if (key === 'minUseKwh') {
        dic.title = '最低消费电量说明';
        dic.content = '参数在电量计费模式下生效。';
      } else if (descKey === 'h5MaxChargePercentDesc') {
        dic.title = '最大充电比例说明';
        dic.content = '参数在电量计费模式下生效，如设置为98%，实际最大充电为0.98度。';
      } else if (descKey === 'h5PowerLossPercentDesc') {
        dic.title = '线损比例说明';
        dic.content = '参数在电量计费模式下生效，如设置为20%，真实用1度电则计算为1.2度。';
      } else if (descKey === 'h5OrderMaxDesc') {
        dic.title = '时长模式的限制功率说明';
        dic.content = '参数在时长计费模式下生效，充电过程中超出该功率自动结束充电并退款。';
      } else if (descKey === 'h5RemoteShutdownSwichDesc') {
        dic.title = '远程结束充电说明';
        dic.content = '充电中的订单在小程序订单详情页显示结束按钮';
      } else if (descKey === 'h5RemoteActivaSwichDesc') {
        dic.title = '远程开始充电说明';
        dic.content = '充电结束的订单在小程序订单详情页显示再次充电按钮';
      }
      this.$Modal.info(dic);
    }
  }
};
</script>
