import BaseModel from '@/libs/base/dataModel/BaseModel';
import { DevicePriceModel } from '@/pages/050__deviceManager/chargingPileList/model/DevicePriceModel';

export class BillingTemplateListModel extends BaseModel {
  constructor () {
    super();
    // 模板id
    this.templateId = '';
    // 模板名称
    this.name = '';
    // 计费方式
    this.category = '';
    // 计费方式描述
    this.categoryDesc = '';
    // 模板创建时间
    this.createTime = '';
    // 模板创建人(姓名+手机号)
    this.createUser = '';
    // 模板状态：启用状态: 0-弃用 1-启用
    this.status = '';
  }

  initData (resData) {
    super.initData(resData);
    return this;
  }

  static createFakeDataList (len = 30) {
    const list = [];
    const billingTypeList = ['按功率计费'];
    const statusList = ['弃用', '启用'];
    for (let i = 0; i < len; i++) {
      const dic = {
        name: BaseModel.getFakeTitle(Math.ceil(Math.random() * 10)),
        templateId: '000101010' + i,
        category: i % 1,
        categoryDesc: billingTypeList[i % 1],
        createTime: '2024-08-2' + i,
        createUser: BaseModel.getFakeTitle(Math.ceil(Math.random() * 5)),
        status: i % 2,
        statusDesc: statusList[i % 2]
      };
      list.push(dic);
    }
    return list;
  }
}

export class BillingTemplateDetailModel extends BillingTemplateListModel {
  constructor () {
    super();
    // 模板名称
    this.name = '';
    // 计费类别（1：功率 2：按时段 3：按电费
    this.category = 1;
    // 时长服务费,单位元, 支持到4位小数 (category=2时返)
    this.timeServicePrice = null;
    // 保底服务费时长, 单位：小时, 支持到2位小数(category=2时返)
    this.minServiceTime = '';
    // 计费规则列表
    this.configs = [];

    this.h5TimeServicePrice = '';
  }

  initData (resData) {
    super.initData(resData);
    if (BaseModel.checkArray(resData.computeFeeRules)) {
      this.configs = DevicePriceModel.initListWithDataList(resData.computeFeeRules);
    } else {
      this.configs = [];
    }
    if (this.category === 2) {
      if (this.timeServicePrice && this.minServiceTime) {
        this.h5TimeServicePrice = `时长服务费单价${this.timeServicePrice}元/小时，保底收费时长${this.minServiceTime}小时，超出${this.minServiceTime}小时后后按分钟计收时长服务费`;
      } else if (this.timeServicePrice && !this.minServiceTime) {
        this.h5TimeServicePrice = `充电时长服务费${this.timeServicePrice}元/小时，按实际分钟计收服务费。`;
      } else {
        this.h5TimeServicePrice = '不收时长服务费';
      }
    }
    return this;
  }

  /**
   * 新增校验-按功率计费
   * @returns {*}
   */
  checkValidAddSubmit () {
    if (this.name.length > 36) {
      return BaseModel.getValidRes(false, '模板名称最多支持36位');
    }
    const configs = [];
    this.configs.forEach((v, index) => {
      configs.push({
        level: v.level,
        category: v.category,
        roadRange: `${v.h5RangeMin}${DevicePriceModel.rangeSplitUnit}${index === (this.configs.length - 1) ? '' : v.h5RangeMax}`,
        chargeDuration: v.chargeDuration,
        price: 100
      });
    });
    return BaseModel.getValidRes(true, '', {
      name: this.name,
      category: this.category,
      configs
    });
  }

  /**
   * 新增校验-按电量计费
   * @returns {*}
   */
  checkValidAddSubmitByDian () {
    if (this.name.length > 36) {
      return BaseModel.getValidRes(false, '模板名称最多支持36位');
    }
    const configs = [];
    this.configs.forEach((v, index) => {
      configs.push({
        level: v.level,
        category: v.category,
        roadRange: `${v.h5RangeMin}${DevicePriceModel.rangeSplitUnit}${v.h5RangeMax}`,
        chargePrice: v.chargePrice,
        servicePrice: v.servicePrice
      });
    });
    return BaseModel.getValidRes(true, '', {
      name: this.name,
      category: this.category,
      configs,
      timeServicePrice: this.timeServicePrice,
      minServiceTime: this.minServiceTime
    });
  }

  /**
   * 新增校验-按时长计费
   * @returns {*}
   */
  checkValidAddSubmitByTime () {
    if (this.name.length > 36) {
      return BaseModel.getValidRes(false, '模板名称最多支持36位');
    }
    const configs = [];
    this.configs.forEach((v, index) => {
      configs.push({
        level: v.level,
        category: v.category,
        roadRange: '0-0',
        price: 1,
        chargeDuration: v.chargeDuration,
        chargePrice: null,
        servicePrice: null
      });
    });
    return BaseModel.getValidRes(true, '', {
      name: this.name,
      category: this.category,
      configs
    });
  }

  static createFakeData () {
    const dic = this.createFakeDataList(1)[0];
    dic.configs = DevicePriceModel.createFakeDataList(5);
    dic.name = dic.templateName;
    return dic;
  }

  static createAdditionModel () {
    const model = new BillingTemplateDetailModel();
    model.configs = DevicePriceModel.createInitList();
    return model;
  }
}

export class BillingTemplateRecordListModel extends BaseModel {
  constructor () {
    super();
    // 模板id
    this.templateId = '';
    // 模板下发记录id
    this.templateConfigRecordId = '';
    // 模板名称
    this.name = '';
    // 下发日期
    this.createTime = '';
    // 小区总数
    this.communityNum = '';
    // 充电桩总数
    this.deviceNum = '';
    // 下发成功数
    this.succNum = '';
    // 下发失败数
    this.failNum = '';
    // 下发操作人
    this.createUser = '';
    // 下发状态：0-待处理 1-处理中 2-处理完成',
    this.status = '';
    this.statusDesc = '';
    this.categoryDesc = '';
    // 前端专用 ==========>
    // 页面中展示的成功条数
    this.h5SuccessAmountStr = '';
    // 页面中展示的失败条数
    this.h5ErrorAmountStr = '';
    // 前端专用 ==========>
  }

  initData (resData) {
    super.initData(resData);
    switch (this.status) {
    case 2:
      this.h5SuccessAmountStr = this.succNum + '条';
      this.h5ErrorAmountStr = this.failNum + '条';
      break;
    default:
      this.h5SuccessAmountStr = '-';
      this.h5ErrorAmountStr = '-';
      break;
    }
    return this;
  }

  static createFakeDataList (len = 30) {
    const list = [];
    const sendStatusList = ['待处理', '处理中', '处理完成'];
    for (let i = 0; i < len; i++) {
      const dic = {
        name: BaseModel.getFakeTitle(Math.ceil(Math.random() * 10)),
        templateId: '000101010' + i,
        status: i % 3,
        statusDesc: sendStatusList[i % 3],
        createTime: '2024-08-2' + i,
        createUser: BaseModel.getFakeTitle(Math.ceil(Math.random() * 5)),
        communityNum: BaseModel.getFakeNumber(Math.ceil(Math.random() * 500), 0),
        deviceNum: BaseModel.getFakeNumber(Math.ceil(Math.random() * 500), 0),
        succNum: BaseModel.getFakeNumber(Math.ceil(Math.random() * 500), 0),
        failNum: BaseModel.getFakeNumber(Math.ceil(Math.random() * 500), 0)
      };
      list.push(dic);
    }
    return list;
  }
}
