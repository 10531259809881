<!--
    Created by 程雨喵'mac on 2024/12/25.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：调整软件版本号
-->
<style lang="less">
.device-change-version {
  display: flex;
  .__g-card {
    flex: 1;
    &+.__g-card {
      margin-left: @containerGap;
    }
    .version-list-right {
      position: relative;
      .disabled-mask {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        .display-flex();
        flex-direction: column;
        border-radius: @borderRadiusMid;
        background-color: rgba(0,0,0,0.7);
        color: @textColorFlip;
        .big {
          font-size: 18px;
        }
        .info {
          margin-top: 10px;
          font-size: 12px;
          span {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>

<template>
  <yg-drawer
    :title="'调整软件版本(设备编码:' + model.deviceNumber + ')'"
    width="100%"
    :back-type="2"
    :show-bottom="false"
    @on-close="onClose"
  >
    <div
      slot="content"
      class="device-change-version"
    >
      <yg-card
        v-permission="'system:device:getDeviceAdjustRecord'"
        title="调整日志"
      >
        <div slot="button">
          <yg-button
            type="text"
            @click="_clickRefresh"
          >
            刷新日志
          </yg-button>
        </div>
        <div
          slot="content"
        >
          <yg-table
            :columns="tableColumns1"
            :data="tableData1"
            :page="page1"
            @on-page-change="onChangePage1"
          />
        </div>
      </yg-card>
      <yg-card
        v-permission="'system:device:getDeviceAvailableVersion'"
        title="设备可用版本"
      >
        <div slot="button">
          <yg-button
            v-permission="'system:device:getDeviceVersions'"
            type="text"
            @click="_clickVersion"
          >
            软件版本管理
          </yg-button>
        </div>
        <div
          slot="content"
          class="version-list-right"
        >
          <yg-table
            :columns="tableColumns2"
            :data="tableData2"
            :page="page2"
            @on-page-change="onChangePage2"
          />
          <div
            v-if="hasNotDoneRecord && tableData1.length"
            class="disabled-mask"
          >
            <div class="big">
              请等待正在处理中的版本结束后操作
            </div>
            <div class="info">
              可刷新日志查看最新状态(<span @click.stop="hasNotDoneRecord = false">我仍要操作</span>)
            </div>
          </div>
        </div>
      </yg-card>
      <!--版本管理-->
      <v-version-list
        v-if="!!dialog.versionModel"
        :model="dialog.versionModel"
        @on-close="onCloseVersionList"
      />
    </div>
  </yg-drawer>
</template>

<script>
import ColumnMixin from '@/pages/050__deviceManager/deviceInitList/mixin/versionTableColumn';
import { VersionRecordListModel, VersionEnableListModel } from '@/pages/050__deviceManager/deviceInitList/model/DeviceVersionSettingModel';
import SoftVersionApiSet from '@/request/api/SoftVersionApiSet';
import vVersionList from '@/pages/030__basicSetting/deviceTypeList/view/version/list.vue';
export default {
  components: { vVersionList },
  mixins: [ColumnMixin],
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      dialog: {
        versionModel: null
      },
      tableData1: [],
      page1: { current: 1, total: 0 },
      tableData2: [],
      page2: { current: 1, total: 0 },
      // 是否存在调整中的日志
      hasNotDoneRecord: true,
      // 外部需要刷新
      needRefresh: false
    };
  },
  created () {
    this.requestData1();
    this.requestData2();
  },
  methods: {
    onClose () {
      this.$emit('on-close', this.needRefresh);
    },
    onCloseVersionList (needRefresh) {
      this.dialog.versionModel = null;
      if (needRefresh) {
        this.onChangePage2(1);
      }
    },
    onChangePage1 (page) {
      this.page1.current = page;
      this.requestData1();
    },
    onChangePage2 (page) {
      this.page2.current = page;
      this.requestData2();
    },
    // 刷新日志
    _clickRefresh () {
      this.onChangePage1(1);
    },
    // 软件版本管理
    _clickVersion () {
      this.dialog.versionModel = this.model;
    },
    // 调整日志
    requestData1 () {
      const api = SoftVersionApiSet.getDeviceAdjustRecord;
      api.params = {
        deviceId: this.model.deviceId,
        pageNo: this.page1.current,
        pageSize: this.$config.pageSize
      };
      this.$http(api).then(res => {
        const resData = res.data;
        this.page1.total = resData.total;
        this.tableData1 = VersionRecordListModel.initListWithDataList(resData.rows);
        this.hasNotDoneRecord = this.tableData1.findIndex(v => v.adjustStatus === 0) !== -1;
      });
    },
    // 设备可用版本
    requestData2 () {
      const api = SoftVersionApiSet.getDeviceAvailableVersion;
      api.params = {
        deviceId: this.model.deviceId,
        pageNo: this.page2.current,
        pageSize: this.$config.pageSize
      };
      this.$http(api).then(res => {
        const resData = res.data;
        this.page2.total = resData.total;
        this.tableData2 = VersionEnableListModel.initListWithDataList(resData.rows);
      });
    },
    // 调整版本
    requestChangeVersion (versionId) {
      console.log(versionId);
      const api = SoftVersionApiSet.adjustDeviceVersion;
      api.params = {
        versionId: versionId,
        deviceId: this.model.deviceId
      };
      api.autoConfig.autoLoading = false;
      api.autoConfig.autoToast = false;
      let showModal = true;
      this.$http(api).catch(e => {
        showModal = false;
        this.$Message.warning(e.msg);
      });
      this.needRefresh = true;
      setTimeout(() => {
        if (showModal) {
          this.$Modal.info({
            title: '操作提示',
            content: '实际操作结果以调整日志为准！',
            onOk: () => {
              this.onChangePage1(1);
            }
          });
        }
      }, 800);
      // this.$Message.success('操作成功');
      // this.onChangePage1(1);
      // this.$http(api).then(res => {
      //   this.needRefresh = true;
      //   this.$Message.success('操作成功');
      //   this.onChangePage1(1);
      // });
    }
  }
};
</script>
