<!--
    Created by 程雨喵'mac on 2024/8/22.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：超翔-高级参数
-->
<style lang="less">
.simplify-theme.simplify-theme-dark {
  .cx-device-params {
    background: @backColorLight;
    .box-style {
      background: rgba(0, 0, 0, 0.1);
      border: 2px solid rgba(0, 0, 0, 0.3);
    }
  }
}
.cx-device-params {
  @chaoXiangWidth: 770px;
  position: relative;
  height: 100%;
  overflow: auto;
  width: @chaoXiangWidth;
  border-radius: @borderRadiusMid;
  background: transparent url("/bg/deviceChaoXiangBg.png") no-repeat;
  background-size: 100% 100%;
  backdrop-filter: hue-rotate(180deg);
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  .box-style {
    padding: @containerGap @containerGap @containerGap * 1.5 0;
    background: rgba(255, 255, 255, 0.15);
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: @borderRadiusMid;
    box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(20px);
  }
  .cx-device-params-scroll-outer {
    //display: flex;
    font-size: 13px;
    //min-height: 100%;
    .params-box {
      position: relative;
      padding: @containerGap @containerGap * 0.5 @containerGap * 0.5 @containerGap;
      overflow: hidden;
      .params-info-box {
        top: 200%;
        animation: globalShowAnimTopNoOpacity @transitionTime15 forwards;
        animation-delay: @transitionTime25;
      }
    }
    .params-info-box {
      position: relative;
      min-height: 100%;
      opacity: 1;
      .info-box {
        position: relative;
        opacity: 1;
        overflow: hidden;
        &+.info-box {
          margin-top: @containerGap;
        }
        &:hover {
          .box-tit {
            .yg-btn-box {
              .__g-button {
                opacity: 1;
              }
            }
          }
          .info-row {
            .info-tip {
              opacity: 1;
              border-color: @textColorLight;
            }
            .info-line {
              &::before {
                opacity: 1;
              }
              &::after {
                opacity: 1;
              }
            }
          }
        }
        @keyframes boxLoadingAnim {
          0% { border-color: rgba(255, 255, 255, 0.3); box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.05);background: rgba(255, 255, 255, 0.1) }
          50% { border-color: rgba(84,110,253, 0.2); box-shadow: 0 0 10px 8px rgba(84,110,253, 0.1);background: rgba(84,110,253, 0.1) }
          100% { border-color: rgba(255, 255, 255, 0.3); box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.05);background: rgba(255, 255, 255, 0.1) }
        }
        &.box-is-loading.box-style {
          animation: boxLoadingAnim 1.5s infinite;
        }
        &.box-is-edit.box-style {
          border-color: rgba(84,110,253, 0.2);
          box-shadow: 0 0 10px 8px rgba(84,110,253, 0.1);
          background: rgba(255, 255, 255, 0.1)
        }
        .box-tit {
          position: relative;
          .display-flex();
          justify-content: space-between;
          padding: 0 0 @containerGap @containerGap;
          font-size: 14px;
          font-weight: 600;
          .tit-inner {
            .display-flex();
            .tit-tag {
              margin-right: 5px;
              text-align: center;
              padding: 0 8px;
              line-height: 16px;
              background-color: @backColorStrong;
              font-size: 12px;
              color: @textColorLight;
              font-weight: 400;
              border-radius: 8px 0 8px 8px;
              box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.05);
              &::after {
                content: '缓存数据'
              }
              &.is-live {
                background-color: @themeColor;
                color: white;
                &::after {
                  content: '实时数据'
                }
              }
            }
          }
          .yg-btn-box {
            .__g-button {
              opacity: 0;
              font-size: 12px;
            }
            .__g-button:last-child {
              margin-left: 10px;
            }
          }
        }
        .info-row {
          padding: @containerGap * 0.5 0;
          display: flex;
          &.no-tip {
            .info-line {
              opacity: 0;
            }
            .info-tip {
              opacity: 0;
            }
          }
          .info-data {
            .flex-grow(235px);
            display: flex;
            align-items: flex-start;
            .info-label {
              padding-left: @containerGap * 0.5;
              .flex-grow(130px);
              text-align: right;
              color: @textColorLight;
            }
            .info-radio {
              .flex-grow(150px);
              margin-left: 5px;
            }
            .info-value {
              margin: 0 5px;
              text-align: center;
              .flex-grow(60px);
              font-weight: 600;
              border-bottom: 1px solid @textColorLight;
              box-sizing: border-box;
              &.value-is-edit {
                border: 1px solid @textColorLight;
                border-radius: 2px;
              }
              .value-input {
                width: 40px;
                border: none;
                outline: none;
                background: transparent;
                text-align: center;
                color: @textColorNormal;
              }
            }
            .info-switch {
              margin-left: 5px;
            }
            .info-unit {
              color: @textColorLight;
            }
          }
          .info-line {
            position: relative;
            .flex-grow(40px);
            .display-flex();
            height: 14px;
            &::before {
              content: '';
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: @textColorLight;
              opacity: 0.4;
            }
            &::after {
              content: '';
              width: 100%;
              height: 1px;
              background-color: @textColorLight;
              opacity: 0.4;
            }
          }
          .info-tip {
            padding: 3px 5px;
            font-size: 12px;
            //display: none;
            white-space: pre-line;
            border-radius: 2px;
            color: @textColorLight;
            background-color: @backColorStrong;
            opacity: 0.4;
            border: 1px solid transparent;
            word-break: break-all;
          }
        }
        .tag {
          position: absolute;
          bottom: 0;
          right: 0;
          //right: -@containerGap;
          //left: -@containerGap * 0.5;
          margin-left: 5px;
          padding: 0 10px;
          font-size: 10px;
          line-height: 16px;
          background-color: @backColorStrong;
          color: @textColorLight;
          border-radius: 8px 0 0 0;
        }
      }
    }
    .cx-img {
      .display-flex();
      padding: 0 0 @containerGap;
      height: 210px;
      overflow: hidden;
      .cx-main {
        padding-left: @containerGap;
        .h4 {
          margin-bottom: @containerGap;
        }
        .cx-text {
          position: relative;
          text-indent: 15px;
          font-size: 10px;
          color: @textColorLight;
          &::before {
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            top: 3px;
            left: 0;
            border-radius: 50%;
            border: 2px solid @themeColor;
          }
        }
        .cx-button-box {
          margin-top: @containerGap * 2;
          .btn {
            box-shadow: 0 0 6px 1px rgba(84,110,253,0.3);
          }
        }
      }
      .img-outer {
        position: relative;
        padding: 0 @containerGap * 2;
        .display-flex();
        height: 100%;
        img {
          position: relative;
          width: 120px;
          height: 160px;
          z-index: 1;
        }
        .under-skew {
          position: absolute;
          width: 100%;
          height: 60px;
          //top: 0;
          //right: -45px;
          bottom: 0;
          //bottom: -20px;
          //left: -45px;
          border-radius: 50%;
          background: rgba(255, 255, 255, 0.6);
          border: 2px solid rgba(255, 255, 255, 0.3);
          box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.05);
          //transform: skew(-20deg);
        }
      }
    }
  }
  .bottom-tip {
    line-height: 30px;
    color: @textColorLight;
    text-align: center;
    font-size: 13px;
    margin-top: 10px;
  }
}
</style>

<template>
  <div class="cx-device-params">
    <div class="cx-device-params-scroll-outer">
      <div class="params-box">
        <!--顶部充电桩介绍-->
        <div class="cx-img">
          <div class="img-outer">
            <img
              src="/bg/deviceChaoXiang.jpg"
              alt=""
            >
            <div class="under-skew" />
          </div>
          <div class="cx-main">
            <p class="h4">
              超翔10路电动自行车充电桩
            </p>
            <p class="cx-text">
              10路电动自行车充电桩具备远程读取电表电量信息，充电过程中意外断电，设备能够记忆未完成的订单，供电正常后可自动恢复订单。
            </p>
            <p class="cx-text">
              本产品内嵌式设计、过流保护、漏电保护、短路自停、充满/拔掉自停，可自动检测电池状态，充满后即刻停止电流输出，智能保障电池安全，避免过充。
            </p>
            <div
              v-permission="'system:device:cx:param:get'"
              class="cx-button-box"
            >
              <yg-button
                size="small"
                type="primary"
                class="btn"
                @click="_clickData"
              >
                <span style="font-size: 10px">一键获取最新高级参数</span>
              </yg-button>
              <!--<yg-button-->
              <!--  size="small"-->
              <!--  style="margin-left: 15px;"-->
              <!--  @click="_clickEdit"-->
              <!--&gt;-->
              <!--  <span style="font-size: 10px">编辑参数</span>-->
              <!--</yg-button>-->
            </div>
          </div>
        </div>
        <!--高级参数-->
        <div
          v-permission="'system:device:cx:param:get'"
          class="params-info-box"
        >
          <div
            v-for="(item, i) in infoItems"
            :key="'ou-' + i"
            class="box-style info-box"
            :class="(loadingIndex === i ? 'box-is-loading ' : '') + (editIndex === i ? 'box-is-edit' : '')"
          >
            <div class="box-tit">
              <div class="tit-inner">
                <span
                  v-if="!dialogData[item.key].noData"
                  class="tit-tag"
                  :class="dialogData[item.key].h5IsStorageData ? '' : 'is-live'"
                />
                <span>{{ item.title }}</span>
              </div>
              <div
                v-if="editIndex === i"
                class="yg-btn-box"
              >
                <yg-button
                  type="default"
                  size="small"
                  @click="_clickCancelEdit(i)"
                >
                  取消编辑
                </yg-button>
                <yg-button
                  type="primary"
                  size="small"
                  @click="_clickSubmit(i, item.key)"
                >
                  提交参数
                </yg-button>
              </div>
              <div
                v-else
                class="yg-btn-box"
              >
                <yg-button
                  type="primary"
                  size="small"
                  @click="_clickOneData(i)"
                >
                  获取最新参数{{ i + 1 }}
                </yg-button>
                <yg-button
                  v-permission="'system:device:cx:param:set'"
                  type="default"
                  size="small"
                  @click="_clickEdit(i, item.key)"
                >
                  编辑参数
                </yg-button>
              </div>
            </div>
            <div
              v-for="(sub, s) in item.items"
              :key="'li-' + s"
              class="info-row"
              :class="!sub.tip ? 'no-tip' : ''"
            >
              <div class="info-data">
                <div class="info-label">
                  {{ sub.label }}
                </div>
                <template v-if="sub.isRadio">
                  <div class="info-radio">
                    <RadioGroup
                      v-model="dialogData[item.key][sub.key]"
                      :vertical="false"
                      style="width: 100%"
                    >
                      <Radio
                        v-for="(cc, ind) in sub.radioOption"
                        :key="ind"
                        :label="cc.value"
                        :disabled="editIndex !== i"
                      >
                        {{ cc.label }}
                      </Radio>
                    </RadioGroup>
                  </div>
                </template>
                <template v-else>
                  <div
                    class="info-value"
                    :class="editIndex === i ? 'value-is-edit' : ''"
                  >
                    <input
                      v-if="editIndex === i"
                      v-model="dialogData[item.key][sub.key]"
                      type="text"
                      class="value-input"
                    >
                    <div
                      v-else
                    >
                      {{ dialogData[item.key][sub.key] || '-' }}
                    </div>
                  </div>
                </template>
                <div class="info-unit">
                  {{ sub.unit }}
                </div>
              </div>
              <div
                class="info-line"
              />
              <div class="info-tip">
                {{ sub.tip }}
              </div>
            </div>
            <span
              v-if="dialogData[item.key].h5IsStorageData"
              class="tag"
            >本条数据数据缓存于{{ dialogData[item.key].h5StorageTime }}</span>
          </div>
        </div>
      </div>
    </div>
    <!--底部提示-->
    <div class="bottom-tip">
      <span v-if="lastReqTime">最后获取时间 {{ lastReqTime }} @</span> 设备编码 {{ model.deviceNumber }}
    </div>
  </div>
</template>

<script>
import DeviceApiSet from '@/request/api/DeviceApiSet';
import { ChaoXiangParamsModel1, ChaoXiangParamsModel2, ChaoXiangParamsModel3 } from '@/pages/050__deviceManager/chargingPileList/model/ChaoXiangParamsModel';
import { getNowTime } from '@/libs/utils';

const MODEL_ARR = [ChaoXiangParamsModel1, ChaoXiangParamsModel2, ChaoXiangParamsModel3];

export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      // 最后请求的时间
      lastReqTime: '',
      dialogData: {
        params1: {},
        params2: {},
        params3: {}
      },
      copyData: {
        index: -1,
        model: null
      },
      // 编辑中的原对象
      editIndex: -1,
      infoItems: [
        {
          title: '高级参数①',
          key: 'params1',
          items: [
            { label: '最大功率', key: 'maxPower', unit: '瓦', tip: '' }
          ]
        },
        {
          title: '高级参数②',
          key: 'params2',
          items: [
            { label: '火灾检测是否禁用', key: 'fireMonitoring', unit: '', tip: '', isRadio: true, radioOption: [{ label: '禁用', value: '0' }, { label: '解禁', value: '1' }] },
            { label: '温度检测是否禁用', key: 'temperatureMonitoring', unit: '', tip: '', isRadio: true, radioOption: [{ label: '禁用', value: '0' }, { label: '解禁', value: '1' }] }
          ]
        },
        {
          title: '高级参数③',
          key: 'params3',
          items: [
            { label: '是否打开线上卡', key: 'openOnlineCard', unit: '', tip: '', isRadio: true, radioOption: [{ label: '关闭', value: '0' }, { label: '打开', value: '1' }] },
            { label: '拔掉断电时间', key: 'moveTime', unit: '秒', tip: '' },
            { label: '拔掉断电功率', key: 'movePower', unit: '瓦', tip: '当功率符合【拔掉断电功率】时并持续了【拔掉断电时间】，结束类型即归为拔掉断电。' },
            { label: '充满自停时间', key: 'stopTime', unit: '分钟', tip: '' },
            { label: '充满自停功率', key: 'stopPower', unit: '瓦', tip: '当功率符合【充满自停功率】时并持续了【充满自停时间】，结束类型即归为充满自停' },
            { label: '空载断电时间', key: 'emptyTime', unit: '秒', tip: '当空载持续了【空载断电时间】，结束类型即归为空载断电。' }
          ]
        }
      ],
      // 记录哪个参数再被请求
      loadingIndex: -1
    };
  },
  watch: {
    editIndex (v) {
      if (v === -1) {
        if (!this.copyData.model) return;
        const name = this.copyData.index + 1;
        this.dialogData['params' + name] = this.copyData.model;
      } else {
        const name = v + 1;
        // 如果是初始化数据就不需要深拷贝（非数据模型的实例）
        if (this.dialogData['params' + name].noData) {
          this.copyData.index = -1;
          this.copyData.model = null;
          return;
        }
        this.copyData.index = v;
        this.copyData.model = MODEL_ARR[v].deepCopy(this.dialogData['params' + name]);
      }
    }
  },
  created () {
    console.log(this.model);
    this.resetData();
  },
  mounted () {
    this.$nextTick(() => {
      const chaoxiangDeviceParams = this.$storage.chaoxiangDeviceParams;
      if (chaoxiangDeviceParams) {
        const deviceData = chaoxiangDeviceParams[this.model.id + ''];
        if (deviceData) {
          const dialogData = this.dialogData;
          this.lastReqTime = deviceData.lastReqTime;
          if (deviceData.params1) dialogData.params1 = ChaoXiangParamsModel1.initModelWithData(deviceData.params1);
          if (deviceData.params2) dialogData.params2 = ChaoXiangParamsModel2.initModelWithData(deviceData.params2);
          if (deviceData.params3) dialogData.params3 = ChaoXiangParamsModel3.initModelWithData(deviceData.params3);
          this.dialogData = dialogData;
        }
      }
    });
  },
  methods: {
    // 重置数据
    resetData () {
      this.editIndex = -1;
      this.loadingIndex = -1;
      this.dialogData = {
        params1: {
          noData: true
        },
        params2: {
          noData: true
        },
        params3: {
          noData: true
        }
      };
    },
    // 存储数据
    saveToStorage (index, params) {
      const chaoxiangDeviceParams = this.$storage.chaoxiangDeviceParams;
      let deviceData = chaoxiangDeviceParams[this.model.id + ''];
      if (!deviceData) {
        deviceData = {};
      }
      deviceData.lastReqTime = getNowTime();
      params.h5IsStorageData = true;
      params.h5StorageTime = deviceData.lastReqTime;
      if (index === 0) {
        deviceData.params1 = params;
      } else if (index === 1) {
        deviceData.params2 = params;
      } else {
        deviceData.params3 = params;
      }
      this.$storage.chaoxiangDeviceParams = {
        key: this.model.id + '',
        data: deviceData
      };
    },
    // 一键获取最新数据
    _clickData () {
      this.$Modal.confirm({
        title: '耗时提醒',
        content: '一键获取全部参数预计将耗时30秒，是否确定？',
        onOk: () => {
          this.$ygLoading.show();
          this.resetData();
          this.requestData();
        }
      });
    },
    // 点击获取单个模块数据
    _clickOneData (i) {
      this.editIndex = -1;
      this.loadingIndex = i;
      this.requestOneData(i);
    },
    // 点击编辑
    _clickEdit (i, key) {
      this.editIndex = -1;
      // 如果是没有数据（实时数据也没有，缓存数据也没有）时，不需要询问用户，先请求数据
      // 这里后面再打开！！！！！
      // const name = i + 1;
      // if (this.dialogData['params' + name].noData) {
      //   this.requestOneData(i);
      //   this.editIndex = i;
      //   return;
      // }
      this.$Modal.confirm({
        title: '温馨提示',
        content: '是否获取最新参数后再编辑？',
        okText: '立即获取',
        cancelText: '直接编辑',
        onOk: () => {
          this.requestOneData(i);
          this.editIndex = i;
        },
        onCancel: () => {
          this.editIndex = i;
        }
      });
    },
    // 点击取消编辑
    _clickCancelEdit () {
      this.editIndex = -1;
      this.loadingIndex = -1;
    },
    // 点击提交
    _clickSubmit (i, key) {
      let resData = null;
      if (i === 0) {
        resData = ChaoXiangParamsModel1.checkValidSubmit(this.dialogData[key]);
      } else if (i === 1) {
        resData = ChaoXiangParamsModel2.checkValidSubmit(this.dialogData[key]);
      } else {
        resData = ChaoXiangParamsModel3.checkValidSubmit(this.dialogData[key]);
      }
      if (!resData.success) {
        return this.$Message.warning(resData.msg);
      }
      this.requestSubmit(i, resData.params);
    },
    // 请求单个模块数据
    requestOneData (i) {
      let api = null;
      if (i === 0) {
        api = DeviceApiSet.deviceCXParam1;
      } else if (i === 1) {
        api = DeviceApiSet.deviceCXParam2;
      } else {
        api = DeviceApiSet.deviceCXParam3;
      }
      api.params = { deviceId: this.model.id };
      api.autoConfig.autoLoading = true;
      this.$http(api).then(resData3 => {
        this.loadingIndex = -1;
        if (i === 0) {
          this.dialogData.params1 = ChaoXiangParamsModel1.initModelWithData(resData3.data);
        } else if (i === 1) {
          this.dialogData.params2 = ChaoXiangParamsModel2.initModelWithData(resData3.data);
        } else {
          this.dialogData.params3 = ChaoXiangParamsModel3.initModelWithData(resData3.data);
        }
        this.saveToStorage(i, resData3.data);
        this.lastReqTime = getNowTime();
      }).catch(e => {
        this.loadingIndex = -1;
        this.$ygLoading.close();
      });
    },
    // 请求所有模块数据
    requestData () {
      const api1 = DeviceApiSet.deviceCXParam1;
      const api2 = DeviceApiSet.deviceCXParam2;
      const api3 = DeviceApiSet.deviceCXParam3;
      api1.params = { deviceId: this.model.id };
      api2.params = { deviceId: this.model.id };
      api3.params = { deviceId: this.model.id };
      api1.autoConfig.autoLoading = false;
      api2.autoConfig.autoLoading = false;
      api3.autoConfig.autoLoading = false;
      this.loadingIndex = 0;
      this.$http(api1).then(resData1 => {
        this.dialogData.params1 = ChaoXiangParamsModel1.initModelWithData(resData1.data);
        this.saveToStorage(0, resData1.data);
        this.loadingIndex = 1;
        this.$http(api2).then(resData2 => {
          this.dialogData.params2 = ChaoXiangParamsModel2.initModelWithData(resData2.data);
          this.saveToStorage(1, resData2.data);
          this.loadingIndex = 2;
          this.$http(api3).then(resData3 => {
            this.loadingIndex = -1;
            this.dialogData.params3 = ChaoXiangParamsModel3.initModelWithData(resData3.data);
            this.saveToStorage(2, resData3.data);
            this.lastReqTime = getNowTime();
            this.$ygLoading.close();
          }).catch(e => {
            this.loadingIndex = -1;
            this.$ygLoading.close();
          });
        }).catch(e => {
          this.loadingIndex = -1;
          this.$ygLoading.close();
        });
      }).catch(e => {
        this.loadingIndex = -1;
        this.$ygLoading.close();
      });
      //   Promise.all([this.$http(api1), this.$http(api2), this.$http(api3)]).then(resArr => {
      //     console.log(resArr);
      //     const resData1 = resArr[0];
      //     const resData2 = resArr[1];
      //     const resData3 = resArr[2];
      //     this.dialogData.params1 = YouDianParamsModel1.initModelWithData(resData1);
      //     this.dialogData.params2 = YouDianParamsModel2.initModelWithData(resData2);
      //     this.dialogData.params3 = YouDianParamsModel3.initModelWithData(resData3);
      //   });
    },
    // 请求提交参数
    requestSubmit (i, params) {
      this.loadingIndex = i;
      let api = null;
      if (i === 0) {
        api = DeviceApiSet.submitDeviceCXParam1;
      } else if (i === 1) {
        api = DeviceApiSet.submitDeviceCXParam2;
      } else {
        api = DeviceApiSet.submitDeviceCXParam3;
      }
      api.params = params;
      api.params.deviceId = this.model.id;
      api.autoConfig.autoLoading = true;
      this.$http(api).then(res => {
        this.$Message.success('设置成功');
        this.saveToStorage(i, params);
        this.lastReqTime = getNowTime();
        this.copyData = {
          model: null,
          index: -1
        };
        this.editIndex = -1;
        this.loadingIndex = -1;
      });
    }
  }
};
</script>
