<!--
    Created by 程雨喵'mac on 2024/11/28.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能：可左右分屏的容器
-->
<style lang="less">
.__g-split-vertical-box {
  position: relative;
  display: flex;
  align-items: flex-start;
  height: 100%;
  .__g-split-box-left {
    position: relative;
    //margin-right: @containerGap;
    min-width: 0;
    .__g-split-open-btn {
      position: absolute;
      top: 0;
      right: -@containerGap;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 10px;
      line-height: 12px;
      background-color: @backColorNormal;
      width: 25px;
      height: 40px;
      border-radius: 20px 0 0 20px;
      box-shadow: @shadowMin;
      color: @textColorLight;
      cursor: pointer;
      &.is-open {
        right: -@containerGap * 2;
        padding-left: 5px;
        border-radius: 0 20px 20px 0;
        .ivu-icon {
          transform: rotateZ(180deg);
        }
      }
      .ivu-icon {
        font-size: 14px;
      }
    }
  }
  .__g-split-box-left, .__g-split-box-right {
    height: 100%;
    flex: 1;
  }
}
</style>

<template>
  <div class="__g-split-vertical-box">
    <div
      class="__g-split-box-left"
    >
      <slot name="left" />
      <div
        v-if="showOpenButton"
        class="__g-split-open-btn"
        :class="currentValue ? 'is-open' : ''"
        @click.stop="_clickOpen"
      >
        <Icon
          v-if="!currentValue"
          type="ios-arrow-back"
        />
        <div>
          <div>扩</div>
          <div>展</div>
        </div>
        <Icon
          v-if="currentValue"
          type="ios-arrow-back"
        />
      </div>
    </div>
    <div
      v-show="currentValue"
      class="__g-split-box-right"
    >
      <slot name="right" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'YgSplitVerticalBox',
  components: {},
  props: {
    // 右侧分栏是否直接打开
    value: {
      type: Boolean,
      default: false
    },
    showOpenButton: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      // 右侧当前打开状态
      currentValue: false
    };
  },
  watch: {
    value (n, o) {
      if (n !== o) {
        this.setCurrentValue(n);
      }
    }
  },
  created () {},
  methods: {
    setCurrentValue (value) {
      this.currentValue = value;
      this.$emit('input', value);
      this.$emit('on-change', value);
    },
    _clickOpen () {
      this.setCurrentValue(!this.currentValue);
    }
  }
};
</script>
