<!--
    Created by 程雨喵'mac on 2024/12/24.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    界面名称：新增-编辑版本
-->
<style lang="less">
.device-version-submit {
  padding-bottom: @containerGap;
  .ivu-form {
    .ivu-form-item {
      display: block;
      .ivu-form-item-label {
        margin-bottom: 5px;
        text-align: left;
        display: block;
        float: none;
      }
      .ivu-form-item-content {
        display: block;
        min-width: 100%;
      }
    }
  }
  .upload-box-outer {
    width: 100%;
    .edit-has-file {
      line-height: 14px;
      font-size: 12px;
    }
  }
}
</style>

<template>
  <yg-modal
    :title="model.id ? '编辑版本' : '新增版本'"
    width="520"
    :sure-button-disabled="buttonDisabled"
    :sure-button-disabled-text="buttonDisabledMsg"
    @on-sure="onSure"
    @on-close="onClose"
  >
    <div
      slot="content"
      class="device-version-submit"
    >
      <Form>
        <FormItem
          v-for="(item, index) in formItems"
          :key="index"
          :label="item.label"
          :required="item.isRequired"
        >
          <Input
            v-if="item.tagName === 'Input'"
            v-model.trim="model[item.key]"
            maxlength="20"
            :placeholder="item.p"
            @input="checkSubmit"
          />
          <Input
            v-else-if="item.tagName === 'Textarea'"
            v-model.trim="model[item.key]"
            maxlength="200"
            show-word-limit
            :rows="3"
            type="textarea"
            :placeholder="item.p"
            style="width: 100%"
            @input="checkSubmit"
          />
          <div
            v-else-if="item.tagName === 'Upload'"
            v-permission="'system:device:factory:upload'"
            class="upload-box-outer"
          >
            <div
              v-if="model.packageUrl"
              class="edit-has-file"
            >
              <span>已上传：</span>
              <span style="margin-right: 5px">{{ model.packageUrl }}</span>
              <yg-button
                type="text"
                @click="_clickDel"
              >
                删除
              </yg-button>
            </div>
            <yg-choose-file
              v-else
              @on-change="onChooseFile"
            />
          </div>
        </FormItem>
      </Form>
    </div>
  </yg-modal>
</template>

<script>
export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      buttonDisabled: true,
      buttonDisabledMsg: '',
      // 表单数据
      formItems: [
        {
          label: '版本号',
          key: 'version',
          tagName: 'Input',
          p: '请输入小于20位的信息',
          isRequired: true
        },
        {
          label: '版本描述',
          key: 'describe',
          tagName: 'Textarea',
          p: '请输入小于200位的信息',
          isRequired: true
        },
        {
          label: '升级指令内容',
          key: 'instructions',
          p: '请输入小于20位的信息',
          tagName: 'Input'
        },
        {
          label: '添加文件',
          key: 'file',
          tagName: 'Upload',
          isRequired: false
        }
      ]
    };
  },
  created () {
    this.checkSubmit();
  },
  methods: {
    onSure () {
      const { params, success, msg } = this.model.checkValidSubmit();
      if (success) {
        this.$emit('on-close', params);
      } else {
        this.$Message.warning(msg);
      }
    },
    onClose () {
      this.$emit('on-close');
    },
    _clickDel () {
      this.model.packageUrl = '';
      this.model.h5IsDelPackageUrl = true;
    },
    // 选择file
    onChooseFile (file) {
      this.model.file = file;
    },
    checkSubmit () {
      const item = this.formItems.find(v => v.isRequired && !this.model[v.key]);
      this.buttonDisabled = !!item;
      this.buttonDisabledMsg = item ? (item.label + '不可为空') : '';
    }
  }
};
</script>
